#multiselect-CheckBox {
  // input[type="checkbox"] {
    margin: 0;
    width: 20px;
    height: 20px;
    border-radius: 2px;
    opacity: 1;
    border: 1px solid #474D55;
    &:checked::after {
        opacity: 1;
        animation-name: fadeInOpacity;
        animation-iteration-count: 1;
        animation-timing-function: ease-in;
        animation-duration: 90ms;
        @keyframes fadeInOpacity {
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        }

        width: 100%;
        height: 100%;
        content: url(../../../img/tick.svg);
        color: white;
        // right: 7px;
        border-radius: 2px;
        background-color: #3eabf0;
        // left: 0px;
        position: absolute;
        // top: 3px;
        font-size: 19px;
    }
// }
}

// .css-tlfecz-indicatorContainer {
//   display: revert !important;
// }
// #react-select-2-listbox{
//     padding: 0px !important;
//     background-color: black !important;
//     width: 100% !important
// }
// .react-select__option{
//     width:100%;
// }
// .multislect-label{
//     padding-left:20px;
//     margin-top: 10px;
// }
// .css-1s2u09g-control, .css-1pahdxg-control{
//     width: auto !important;
// }

// .css-26l3qy-menu{
//     padding: 0px !important
// }
.arrow-up-style{
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDUiIGhlaWdodD0iMjcuNSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KIDwhLS0gQ3JlYXRlZCB3aXRoIE1ldGhvZCBEcmF3IC0gaHR0cDovL2dpdGh1Yi5jb20vZHVvcGl4ZWwvTWV0aG9kLURyYXcvIC0tPgogPGc+CiAgPHRpdGxlPmJhY2tncm91bmQ8L3RpdGxlPgogIDxyZWN0IGZpbGw9Im5vbmUiIGlkPSJjYW52YXNfYmFja2dyb3VuZCIgaGVpZ2h0PSIyOS41IiB3aWR0aD0iNDciIHk9Ii0xIiB4PSItMSIvPgogIDxnIGRpc3BsYXk9Im5vbmUiIG92ZXJmbG93PSJ2aXNpYmxlIiB5PSIwIiB4PSIwIiBoZWlnaHQ9IjEwMCUiIHdpZHRoPSIxMDAlIiBpZD0iY2FudmFzR3JpZCI+CiAgIDxyZWN0IGZpbGw9InVybCgjZ3JpZHBhdHRlcm4pIiBzdHJva2Utd2lkdGg9IjAiIHk9IjAiIHg9IjAiIGhlaWdodD0iMTAwJSIgd2lkdGg9IjEwMCUiLz4KICA8L2c+CiA8L2c+CiA8Zz4KICA8dGl0bGU+TGF5ZXIgMTwvdGl0bGU+CiAgPGcgaWQ9InN2Z18xNiI+CiAgIDxsaW5lIHN0cm9rZS1saW5lY2FwPSJ1bmRlZmluZWQiIHN0cm9rZS1saW5lam9pbj0idW5kZWZpbmVkIiBpZD0ic3ZnXzE0IiB5Mj0iMjMuOTg2NDkzIiB4Mj0iMjQuMTE1NTE0IiB5MT0iMy40MjkxNjciIHgxPSIzLjU1ODE4OSIgc3Ryb2tlLXdpZHRoPSIzIiBzdHJva2U9IiM5OTk5OTkiIGZpbGw9Im5vbmUiLz4KICAgPGxpbmUgdHJhbnNmb3JtPSJyb3RhdGUoOTAgMzIuMzI0MDMxODI5ODMzOTksMTMuNzA3ODIwODkyMzMzOTg4KSAiIHN0cm9rZS1saW5lY2FwPSJ1bmRlZmluZWQiIHN0cm9rZS1saW5lam9pbj0idW5kZWZpbmVkIiBpZD0ic3ZnXzE1IiB5Mj0iMjMuOTg2NDkzIiB4Mj0iNDIuNjAyNjk1IiB5MT0iMy40MjkxNjciIHgxPSIyMi4wNDUzNjkiIHN0cm9rZS13aWR0aD0iMyIgc3Ryb2tlPSIjOTk5OTk5IiBmaWxsPSJub25lIi8+CiAgPC9nPgogPC9nPgo8L3N2Zz4=');
  background-size: 14px 8px;
  background-position: calc(100% - 12px) center;
  background-repeat: no-repeat;

}