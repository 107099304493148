.breadcrumb-container {
  overflow: hidden;
  display: flex;
  flex-wrap: nowrap;
  padding: 5px;
  line-height: 28px;
  top: 93px;
  left: 204px;
  text-align: left;
  font: normal normal bolder 18px/22px Montserrat;
  letter-spacing: 0px;
  text-transform: uppercase;
  opacity: 1;
  color: #68717e;
}

.breadcrumb-item {
  font-size:17px;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-left: 10px;
  padding-right: 10px;
}
.breadcrumb-item:hover {
  color:  #3eabf0;
  font-size:17px;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-left: 10px;
  padding-right: 10px;
}




.breadcrumb-item:last-child {
  color: #ffffff;
}
.breadcrumb-item-separator {
  margin-right: 5px;
  margin-left: 5px;
}

.breadcrumb-item-dropdown-button {
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  line-height: 22px;
}

/* .breadcrumb-item-dropdown-button:hover {
    color: #161616;
} */
