// .page-container {
//   width: 100%;
//   max-width: $max-width;
//   margin: 0 auto;
//   padding: 20px;
// }

.page-hero {
  position: relative;
  width: 100%;
  height: 220px;
  background-image: url('https://cdn.wallpaperhub.app/cloudcache/1/b/5/8/e/f/1b58ef6e3d36a42e01992accf5c52d6eea244353.jpg');
  background-size: cover;
  background-position: center;
  margin-bottom: -100px;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, $content-bg-color 0%, rgba($content-bg-color, 0.2) 100%);
  }
}

.tabs {
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #444;

  .tab {
    display: inline-block;
    cursor: pointer;
    margin-right: 50px;
    font-size: 1.2rem;
    color: white;
    text-decoration: none;

    &.active {
      border-bottom: 1px solid white;
    }
  }
}
