.organaizer-card-main-div {
    margin: 10px 20px 10px 0px;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    // background-color: black;
    // padding-bottom: 20px;
    // flex: 0 1 calc(16% - 20px); //For 5 items per row => also check css of it container div
    // &:hover{
    //     h3,.last-edited-OC,.border-clip-for-color{
    //         color: var(--primary);
    //     }
        
    // }
    flex: 0 0 270px;
    .details-card-OC {
        padding: 5px;
        .logo-name-dots {
            display: flex;
            justify-content: space-between;
            .logo-and-name {
                display: flex;
                // padding: 1px 0px 0px 5px;
                flex-direction: row;
                align-items: center;
                flex-wrap: nowrap;
                flex: 0 0 calc(85%);
                .logo-OC {
                    align-items: center;
                    display: flex;
                    margin-right: 5px;
                    img {
                        width: 20px;
                        height: 20px;
                    }
                }
                .item-title-OC {
                    -webkit-user-select: text;
                    -moz-user-select: text;
                    -o-user-select: text;
                    user-select: text;
                    display: flex;
                    align-items: center;
                    // padding-left: 5px;
                    text-align: left;
                    h3 {
                        text-overflow: ellipsis;
                        max-width: 198px;
                        white-space: nowrap;
                        margin: 0px;
                        min-width: 159px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        text-align: left;
                        font: normal normal bold 12px/15px Montserrat;
                        letter-spacing: 0px;
                        color: #ffffff;
                        text-transform: uppercase;
                        opacity: 1;
                    }
                }
            }
            .three-dots-OC {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                flex: 0 0 calc(15%);
                .tippy-box {
                    background-color: #0f1116;
                }
            }
        }
        .last-edited-OC {
            -webkit-user-select: text;
            -moz-user-select: text;
            -o-user-select: text;
            user-select: text;
            text-align: left;
            // font: normal normal normal 10px/26px Montserrat;
            font: normal normal normal 11px/15px Montserrat;
            letter-spacing: 0px;
            color: #68717e;
            opacity: 1;
        }
    }
}
