@import './../../../../styles/variables';

.org-modal {
  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 550px;
  transform: translate(-50%, -50%);
  background: #171a22;
  color: #fff;
  outline: 0;
  // border: 10px solid #34373c;
  border-radius: 3px;
  z-index: 999;
  max-height: calc(100vh - 100px);
  align-items: center;

  //overflow: hidden;
  &.match-modal {
    width: 1100px;
  }

  &.modal-inline {
    position: relative;

    &.on-page {
      z-index: 9;
    }
  }

  .modal-close {
    width: inherit;
    top: -7%;
    margin-top: 6%;
    padding-right: 5.5%;
    // padding:20px;
    // right: 15px;
    position: absolute;
    color: white;
    font-size: 20px;
    cursor: pointer;
    display: none;

  }



  .modal-scrollable {
    overflow: auto;
    max-height: calc(100vh - 100px);
  }

  .modal-header {
    // padding: 20px;
    //   border-bottom: 1px solid $tab-border-color;

    h2 {
      font: normal normal bold 18px/22px Montserrat;
      // font-size: 1.2rem;
      font-weight: bold;
      text-transform: uppercase;
      // padding: 20px;
      padding-left: 5%;
      // margin-bottom: 15px;
    }

    h3 {
      font-size: 0.75rem;
      line-height: 1rem;
      font-weight: normal;
      // color: $text-faded-color;
    }
  }
  // .input{
  //   padding: 20px;
  //   background-color: 'transparent'
  // }
}
