@import 'Header/header';
@import 'Footer/footer';
@import 'Sidebar/sidebar';
// @import "GameKeys/game-keys";

.global-main {
  display: flex;
}

.global-content {
  position: relative;
  flex-grow: 1;
  color: white;
  // color:'#68717e';
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.veto-timer-alert {
  .icon {
    margin-right: 0px;
    margin-top: 1px;
    img {
      filter: invert(100%) sepia(3%) saturate(7500%) hue-rotate(161deg) brightness(102%)
        contrast(103%);
      padding: 0px 5px;
      margin-top: 3px;
    }
  }
  color: #000;
  font-weight: normal !important;
  // .veto-close-btn {
  //   .icon {
  //     margin-right: 12px;
  //   }
  // }
}
