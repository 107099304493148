$header-bg-color: #0f1116;
$footer-bg-color: #0f1116;
$sidebar-bg-color: #12141a;
$header-sidebar-border-color: #22262e;
$content-bg-color: #03060d;
$modal-bg-color: #141414;
$hr-color: #2c2c2c;
$h-color: #fff;
$tab-link-unactive: #68717e;
$tab-border-color: #22262e;

$font-stack: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial,
  sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';

$width: 100%;
$max-width: 1500px;
$fluid-max-width: 2000px;
$gutter: 30px;
$gutter-m: 15px;
$gutter-sm: 7.5px;
$breakpoint-small: 540px; // 540px
$breakpoint-med: 720px; // 720px
$breakpoint-large: 960px; // 960px
$breakpoint-xlarge: 1200px; // 1200px

$bg-faded: #12141a;
$primary: #3eabf0;
$success: #19db60;
$danger: #f11857;
$secondary-red: #f40d54;
$danger-secondary: #fe1149;
$accent-red: #e74c3c;
$white: #fff;
$gray: #68717e;
$orange: #d5901f;
$warning: $orange;
$border-color: #22262e;
$text-faded-color: #68717e;
$text-grey-color: #c2c2c2;
$dark-bg-color: #20242c;
$success-bg-color: #1a2b20;
$danger-bg-color: #261515;
$dropdown-bg: #191b24;
$input-text-color: #68717e;

:export {
  content_bg_color: $content-bg-color;
  primary: var(--primary);
  gray: var(--gray);
  red: var(--danger);

}
