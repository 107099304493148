// appointment Create Modal

.appointmentModal {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 990px;
  transform: translate(-50%, -50%);
  background: $bg-faded;
  color: #fff;
  outline: 0;
  border-radius: 3px;
  z-index: 999;
  max-height: calc(100vh - 100px);

  //overflow: hidden;
  &.match-modal {
    width: 1100px;
  }

  &.modal-inline {
    position: relative;

    &.on-page {
      z-index: 9;
    }
  }

  .modal-close {
    top: 10px;
    right: -45px;
    position: absolute;
    color: white;
    font-size: 30px;
    cursor: pointer;
  }

  .modal-scrollable {
    overflow: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 100px);
  }

  .modal-header {
    text-align: center;
    padding: 15px 20px;
    // border-bottom: 1px solid $tab-border-color;

    h2 {
      font-size: 1.2rem;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 15px;
    }

    h3 {
      font-size: 0.75rem;
      line-height: 1rem;
      font-weight: normal;
      color: $text-faded-color;
    }
  }

  .modal-body {
    padding: 20px;

    .label {
      color: #535a65 !important;
    }

    .next-buttons {
      display: flex;
      justify-content: space-between;
    }
  }

  .modal-information {
    border-top: 1px solid #22262e;
    margin-left: -20px;
    margin-right: -20px;
    padding: 10px 20px;
    background: #22262e;

    h3 {
      font-size: 1.2rem;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 15px;
    }

    p {
      font-size: 0.75rem;
      line-height: 1rem;
      font-weight: normal;
      color: #68717e;
    }
  }

  .form-input-error {
    position: absolute;
    left: 12px;
  }

  .title {
    font-weight: bold;
    text-transform: uppercase;
    border-bottom: 1px solid #535a65;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    margin: 0px -30px 0px 0px;
    width: 506px;
    height: 43px;
    display: block;
    position: relative;
    padding: 13.5px;
    background-color: transparent;
    color: white;
    outline: 0;
    font-size: 0.9rem;
    line-height: 15px;
    appearance: none;
    transition: border 0.3s;
    resize: none;

    &::placeholder {
      color: #535a65;
    }
  }

  .appointmentButton {
    float: right;
    display: block;
    height: 43px;
    padding: 9px 20px;
    font-weight: bold;
    font-size: 12px;
    text-transform: uppercase;
    text-align: center;
    background-color: var(--primary);
    color: white;
    border: 0;
    border-radius: 3px;
    outline: 0;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-in-out;

    &:hover {
      box-shadow: -1px -1px 5px var(--primary), 1px -1px 5px var(--primary),
        -1px 1px 5px var(--primary), 1px 1px 5px var(--primary);
    }
  }

  .appointmentDate {
    height: 40px;
    font-weight: bold !important;
    margin-top: 2px !important;
    margin-bottom: 10px !important;
    width: 150px;
    border: 1px solid #535a65;
    font-size: 11px;
    display: inline;
    margin-left: 0px !important;
    margin-right: 5px !important;
    padding: 13.5px;
    background-color: #12141a;
    border-radius: 2px;
    color: white;
    line-height: 15px;
  }

  .invalidDate {
    border: 1px solid #e74c3c !important;
  }

  .time {
    font-weight: bold !important;
    margin-left: 5px !important;
    margin-bottom: 10px !important;
    margin-top: 2px !important;
    margin-right: 8px !important;
    width: 114px;
    border: 1px solid #535a65;
    font-size: 11px;
    display: inline;
    position: relative;
    padding: 13.5px;
    background-color: #12141a;
    border-radius: 2px;
    color: white;
  }

  .invalidTime {
    border: 1px solid #e74c3c !important;
  }

  .whenDropdown {
    margin-top: -20px !important;
    font-weight: bold !important;
    margin-bottom: 20px !important;
    background-color: #12141a;
    font-size: 11px !important;
    border: 1px solid #535a65;
    width: 180px;
  }

  .notificationDropdown {
    margin-right: 10px;
    display: inline !important;
    position: relative !important;
    font-weight: bold !important;
    margin-bottom: 0px !important;
    background-color: #12141a;
    font-size: 11px !important;
    border: 1px solid #535a65;
    width: 150px;
  }

  .notificationInput {
    margin-right: 10px !important;
    font-weight: bold !important;
    margin-top: 2px !important;
    margin-bottom: 0px !important;
    width: 70px;
    border: 1px solid #535a65;
    font-size: 11px;
    display: inline;
    margin-left: 0px !important;
    padding: 13.5px;
    background-color: #12141a;
  }

  .notificationInputInvalid {
    border: 1px solid #e74c3c !important;
  }

  .deleteNotificationButton {
    display: inline;
  }

  .deleteImage {
    &:hover {
      cursor: pointer;
    }
  }

  .notificationButton {
    margin-bottom: 20px !important;
    height: auto;
    width: auto;
    padding: 9px 20px;
    font-weight: bold;
    font-size: 11px;
    text-transform: uppercase;
    text-align: center;
    background: transparentize($primary, 0.9);
    color: var(--primary);
    border: 0;
    border-radius: 3px;
    outline: 0;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    display: block;

    &:hover {
      box-shadow: -1px -1px 5px var(--primary), 1px -1px 5px var(--primary),
        -1px 1px 5px var(--primary), 1px 1px 5px var(--primary);
    }

    &:disabled {
      cursor: not-allowed;
      box-shadow: none !important;
    }
  }

  .notificationButtonCsgo {
    margin-bottom: 20px !important;
    height: auto;
    width: auto;
    padding: 9px 20px;
    font-weight: bold;
    font-size: 11px;
    text-transform: uppercase;
    text-align: center;
    background: transparentize($accent-red, 0.9);
    color: var(--primary);
    border: 0;
    border-radius: 3px;
    outline: 0;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    display: block;

    &:hover {
      box-shadow: -1px -1px 5px var(--primary), 1px -1px 5px var(--primary),
        -1px 1px 5px var(--primary), 1px 1px 5px var(--primary);
    }

    &:disabled {
      cursor: not-allowed;
      box-shadow: 0px;
    }
  }

  .typeDot {
    width: 20px !important;
    height: 1px !important;
    border-radius: 3px !important;
    display: inline;
    margin-left: 6px;
    margin-right: 11px;
  }

  .typeDropdown {
    position: relative;
    display: inline;
    font-weight: bold !important;
    margin-bottom: 20px !important;
    background-color: #12141a;
    font-size: 11px !important;
    border: 1px solid #535a65;
  }

  .inviteDropdown {
    position: relative;
    display: inline;
    font-weight: bold !important;
    margin-bottom: 5px !important;
    background-color: #12141a;
    font-size: 11px !important;
    border: 1px solid #535a65;
  }

  .dynamicSearch {
    background-color: transparent;
    padding: 10px;
    height: 40px;
    font-size: 1rem;
    line-height: 40px;
    border-color: 1px solid #535a65;
    font-size: 0.9rem;
    z-index: 20;
  }

  .profile-img-with-inital-1-letter-modal {
    flex-shrink: 0;
    width: 33px;
    height: 33px;
    background-color: #444;
    background-position: 50% 50%;
    border-radius: 100%;
    margin-right: 6px;
    padding: 8px 0px 0px 12px;
  }
}

// .css-yt9ioa-option {
//   padding: 5px 0px 5px 0px !important;
// }

// .css-1n7v3ny-option {
//   background-color: transparent !important;
//   padding: 0px 0px !important;
//   &:hover {
//     background-color: #1a57b2 !important;
//     padding: 5px 0px 5px 0px !important;
//   }
// }
// .css-4ljt47-MenuList div#react-select-2-option-0 {
//   padding-top: 10px !important;
//   padding-bottom: 10px !important;
// }
// .css-4ljt47-MenuList div#react-select-2-option-2 {
//   padding-top: 10px !important;
//   padding-bottom: 10px !important;
// }
// .css-9gakcf-option {
//   background-color: #1a57b2 !important;
//   padding: 5px 0px 5px 0px !important;
// }

// .css-4ljt47-MenuList {
//   padding-bottom: 1px !important;
//   padding-top: 1px !important;
//   color: white !important;
//   background-color: transparent !important;
// }

// .css-qc6sy-singleValue {
//   color: white !important;
// }

// .css-1pahdxg-control {
//   box-shadow: none !important;
//   color: white;
//   font-weight: bold !important;
//   margin-bottom: 20px !important;
//   background-color: transparent !important;
//   font-size: 11px !important;
//   border: 1px solid #535a65 !important;
//   background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDUiIGhlaWdodD0iMjcuNSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KIDwhLS0gQ3JlYXRlZCB3aXRoIE1ldGhvZCBEcmF3IC0gaHR0cDovL2dpdGh1Yi5jb20vZHVvcGl4ZWwvTWV0aG9kLURyYXcvIC0tPgogPGc+CiAgPHRpdGxlPmJhY2tncm91bmQ8L3RpdGxlPgogIDxyZWN0IGZpbGw9Im5vbmUiIGlkPSJjYW52YXNfYmFja2dyb3VuZCIgaGVpZ2h0PSIyOS41IiB3aWR0aD0iNDciIHk9Ii0xIiB4PSItMSIvPgogIDxnIGRpc3BsYXk9Im5vbmUiIG92ZXJmbG93PSJ2aXNpYmxlIiB5PSIwIiB4PSIwIiBoZWlnaHQ9IjEwMCUiIHdpZHRoPSIxMDAlIiBpZD0iY2FudmFzR3JpZCI+CiAgIDxyZWN0IGZpbGw9InVybCgjZ3JpZHBhdHRlcm4pIiBzdHJva2Utd2lkdGg9IjAiIHk9IjAiIHg9IjAiIGhlaWdodD0iMTAwJSIgd2lkdGg9IjEwMCUiLz4KICA8L2c+CiA8L2c+CiA8Zz4KICA8dGl0bGU+TGF5ZXIgMTwvdGl0bGU+CiAgPGcgaWQ9InN2Z18xNiI+CiAgIDxsaW5lIHN0cm9rZS1saW5lY2FwPSJ1bmRlZmluZWQiIHN0cm9rZS1saW5lam9pbj0idW5kZWZpbmVkIiBpZD0ic3ZnXzE0IiB5Mj0iMjMuOTg2NDkzIiB4Mj0iMjQuMTE1NTE0IiB5MT0iMy40MjkxNjciIHgxPSIzLjU1ODE4OSIgc3Ryb2tlLXdpZHRoPSIzIiBzdHJva2U9IiM5OTk5OTkiIGZpbGw9Im5vbmUiLz4KICAgPGxpbmUgdHJhbnNmb3JtPSJyb3RhdGUoOTAgMzIuMzI0MDMxODI5ODMzOTksMTMuNzA3ODIwODkyMzMzOTg4KSAiIHN0cm9rZS1saW5lY2FwPSJ1bmRlZmluZWQiIHN0cm9rZS1saW5lam9pbj0idW5kZWZpbmVkIiBpZD0ic3ZnXzE1IiB5Mj0iMjMuOTg2NDkzIiB4Mj0iNDIuNjAyNjk1IiB5MT0iMy40MjkxNjciIHgxPSIyMi4wNDUzNjkiIHN0cm9rZS13aWR0aD0iMyIgc3Ryb2tlPSIjOTk5OTk5IiBmaWxsPSJub25lIi8+CiAgPC9nPgogPC9nPgo8L3N2Zz4=);
//   background-size: 14px 8px;
//   background-position: calc(100% - 12px) center;
//   background-repeat: no-repeat;
//   display: inline;
//   position: relative;
//   width: 370px;
//   padding: 13.5px;
//   color: white !important;
//   outline: 0;
//   line-height: 15px;
//   -webkit-appearance: none;
//   appearance: none;
//   transition: border 0.3s;
// }

// .css-1s2u09g-control {
//   color: white;
//   border-radius: 2px !important;
//   font-weight: bold !important;
//   margin-bottom: 20px !important;
//   background-color: transparent !important;
//   font-size: 11px !important;
//   border: 1px solid #535a65 !important;
//   background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDUiIGhlaWdodD0iMjcuNSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KIDwhLS0gQ3JlYXRlZCB3aXRoIE1ldGhvZCBEcmF3IC0gaHR0cDovL2dpdGh1Yi5jb20vZHVvcGl4ZWwvTWV0aG9kLURyYXcvIC0tPgogPGc+CiAgPHRpdGxlPmJhY2tncm91bmQ8L3RpdGxlPgogIDxyZWN0IGZpbGw9Im5vbmUiIGlkPSJjYW52YXNfYmFja2dyb3VuZCIgaGVpZ2h0PSIyOS41IiB3aWR0aD0iNDciIHk9Ii0xIiB4PSItMSIvPgogIDxnIGRpc3BsYXk9Im5vbmUiIG92ZXJmbG93PSJ2aXNpYmxlIiB5PSIwIiB4PSIwIiBoZWlnaHQ9IjEwMCUiIHdpZHRoPSIxMDAlIiBpZD0iY2FudmFzR3JpZCI+CiAgIDxyZWN0IGZpbGw9InVybCgjZ3JpZHBhdHRlcm4pIiBzdHJva2Utd2lkdGg9IjAiIHk9IjAiIHg9IjAiIGhlaWdodD0iMTAwJSIgd2lkdGg9IjEwMCUiLz4KICA8L2c+CiA8L2c+CiA8Zz4KICA8dGl0bGU+TGF5ZXIgMTwvdGl0bGU+CiAgPGcgaWQ9InN2Z18xNiI+CiAgIDxsaW5lIHN0cm9rZS1saW5lY2FwPSJ1bmRlZmluZWQiIHN0cm9rZS1saW5lam9pbj0idW5kZWZpbmVkIiBpZD0ic3ZnXzE0IiB5Mj0iMjMuOTg2NDkzIiB4Mj0iMjQuMTE1NTE0IiB5MT0iMy40MjkxNjciIHgxPSIzLjU1ODE4OSIgc3Ryb2tlLXdpZHRoPSIzIiBzdHJva2U9IiM5OTk5OTkiIGZpbGw9Im5vbmUiLz4KICAgPGxpbmUgdHJhbnNmb3JtPSJyb3RhdGUoOTAgMzIuMzI0MDMxODI5ODMzOTksMTMuNzA3ODIwODkyMzMzOTg4KSAiIHN0cm9rZS1saW5lY2FwPSJ1bmRlZmluZWQiIHN0cm9rZS1saW5lam9pbj0idW5kZWZpbmVkIiBpZD0ic3ZnXzE1IiB5Mj0iMjMuOTg2NDkzIiB4Mj0iNDIuNjAyNjk1IiB5MT0iMy40MjkxNjciIHgxPSIyMi4wNDUzNjkiIHN0cm9rZS13aWR0aD0iMyIgc3Ryb2tlPSIjOTk5OTk5IiBmaWxsPSJub25lIi8+CiAgPC9nPgogPC9nPgo8L3N2Zz4=);
//   background-size: 14px 8px;
//   background-position: calc(100% - 12px) center;
//   background-repeat: no-repeat;
//   display: inline;
//   position: relative;
//   width: 370px;
//   padding: 13.5px;
//   color: white !important;
//   outline: 0;
//   line-height: 15px;
//   -webkit-appearance: none;
//   appearance: none;
//   transition: border 0.3s;

//   &:hover {
//     border-color: var(--primary) !important;
//   }
// }

// .css-26l3qy-menu {
//   margin-top: 2px !important;
//   margin-bottom: 0px !important;
//   padding: 1px !important;
//   font-weight: bold !important;
//   font-size: 11px !important;
//   background-color: #24262a !important;
//   width: 370px !important;
//   padding: 13.5px !important;
//   color: white !important;
//   line-height: 30px !important;
//   -webkit-appearance: none !important;
//   appearance: none !important;
//   transition: border 0.3s !important;
//   border: 1px solid grey !important;
// }

// .css-tlfecz-indicatorContainer {
//   display: none !important;
// }

// .css-1hb7zxy-IndicatorsContainer {
//   display: none !important;
// }
// .css-1wy0on6 {
//   display: none !important;
// }
.fc-more-link[aria-expanded='true'] {
  box-shadow: 0px 0px 0px 1000px rgba(0, 0, 000, 0.7);
  z-index: 999;
}
.appoinment-del {
  float: right;
  padding-bottom: 20px;
}
.appoinment-del-btn {
  padding: 10px 30px;
  background: #fd1049;
  border: none;
  border-radius: 10px;
  color: white;
  font-weight: 600;
  cursor: pointer;
}
