input#name:hover{
    outline: 0px;
    border: 1px solid var(--primary);
    height: 44px;
}
input#name:hover:focus-visible{
    outline:0px
}
input#name:focus-visible{
    outline:1px solid var(--primary);
    border: 0px solid var(--primary)
}
input#name{
    border: 1px solid #22262e;
}