.generate-matches-modal {
    input[type="time"]{
        height: 44px;
        margin: 0px;
    }
    input[type="datetime-local"]{
        height: 44px;
        margin: 0px;
    }

    width: 450px !important;
    input[type="checkbox"] {
        margin: 0;
        width: 20px;
        height: 20px;
        border-radius: 2px;
        opacity: 1;
        border: 1px solid #474d55;
        &:checked::after {
            opacity: 1;
            animation-name: fadeInOpacity;
            animation-iteration-count: 1;
            animation-timing-function: ease-in;
            animation-duration: 90ms;
            @keyframes fadeInOpacity {
                0% {
                    opacity: 0;
                }
                100% {
                    opacity: 1;
                }
            }
            border-radius: 2px;
            width: 100%;
            height: 100%;
            content: url(../../../img/tick.svg);
            color: white;
            background-color: #3eabf0;
            position: absolute;
            font-size: 19px;
        }
    }
    .row {
        margin: 0;
    }
    .col {
        padding: 0;
    }
    .modal-header {
        .header-pill {
            // min-width: 194px;
            padding: 0 10px 0 10px;
            width: fit-content;
            height: 24px;
            /* UI Properties */
            background:  #68717e 0% 0% no-repeat padding-box; // Earlier is was this #0c293b but as per document i changed it to 
            border-radius: 100px;
            opacity: 1;
            align-items: center;
            justify-content: center;

            span {
                height: 13px;
                /* UI Properties */
                text-align: left;
                font: normal normal bold 10px/13px Montserrat;
                letter-spacing: 0px;
                color: #ffffff; // was this #3eabf0;
                opacity: 1;
                text-transform: uppercase;
            }
        }
    }
    .main-progress-row {
        flex-wrap: nowrap;
        justify-content: center;
        margin-top: -9px; //to make it over to top

        .single-prgress-container {
            flex: 0 0 30%;
            padding: 0;
            display: flex;
            flex-direction: column;
            align-content: center;
            align-items: center;
            justify-content: center;
            .progress-text-bright {
                margin-top: 10px;
                height: 13px;
                /* UI Properties */
                text-align: center;
                font: normal normal bold 10px/13px Montserrat;
                letter-spacing: 0px;
                color: #3eabf0;
                text-transform: uppercase;
                opacity: 1;
                flex-wrap: nowrap;
                justify-content: center;
            }
            .progress-text-dull {
                margin-top: 10px;
                height: 13px;
                /* UI Properties */
                text-align: center;
                font: normal normal bold 10px/13px Montserrat;
                letter-spacing: 0px;
                color: #16405A;
                text-transform: uppercase;
                opacity: 1;
                flex-wrap: nowrap;
                justify-content: center;
            }
            .dot-and-line {
                flex-wrap: nowrap;
                align-items: center;
                width: 100%;
                align-content: flex-end;
                flex-direction: row-reverse;
                justify-content: flex-end;
                .progress-line-bright {
                    width: 47%;
                    border: 1px solid #3eabf0;
                    height: 0px;
                }
                .progress-line-dull {
                    width: 47%;
                    border: 1px solid #16405A;
                    height: 0px;
                }
                .progress-dot-bright {
                    width: 18px;
                    height: 18px;
                    /* UI Properties */
                    background-position-x: 0;
                    background-position-y: 0;
                    background-repeat: no-repeat;
                    background-clip: padding-box;
                    // background: #3eabf0 0% 0% no-repeat padding-box;
                    background-color: #3eabf0;
                    box-shadow: 0px 0px 10px #3eabf0;
                    opacity: 1;
                    border-radius: 50%;
                    animation-name: fadeInOpacity;
                    // animation-iteration-count: 1;
                    animation-timing-function: ease-in;
                    animation-duration: 1000ms;
                    @keyframes fadeInOpacity {
                        0% {
                            opacity: 0;
                        }
                        100% {
                            opacity: 1;
                        }
                    }
                }
                .progress-dot-dull {
                    width: 18px;
                    height: 18px;
                    /* UI Properties */
                    background-position-x: 0;
                    background-position-y: 0;
                    background-repeat: no-repeat;
                    background-clip: padding-box;
                    // background: #3eabf0 0% 0% no-repeat padding-box;
                    background-color: #16405A;
                    // box-shadow: 0px 0px 10px #3eabf0;
                    opacity: 1;
                    border-radius: 50%;
                    animation-name: fadeInOpacity;
                    // animation-iteration-count: 1;
                    // animation-timing-function: ease-in;
                    // animation-duration: 1000ms;
                    // @keyframes fadeInOpacity {
                    //     0% {
                    //         opacity: 0;
                    //     }
                    //     100% {
                    //         opacity: 1;
                    //     }
                    // }
                }
            }
        }
    }
    .modal-body {
        min-height: 400px;
        overflow-x: hidden;
    }
    .E129 {
        overflow-x: hidden;
    }
    .generate-matches-footer {
        border-top: 1px solid #22262e;
        .footer-buttons {
            flex-wrap: nowrap;
            flex-direction: row;
            align-content: center;
            justify-content: space-between;
            align-items: center;
            padding: 15px;
        }
        .back-page-btn {
            min-width: 61px;
            height: 42px;
            border: 1px solid #22262e;
            background: transparent;
            border-radius: 2px;
            opacity: 1;
            span {
                height: 15px;
                /* UI Properties */
                text-align: right;
                font: normal normal bold 12px/15px Montserrat;
                letter-spacing: 0px;
                color: #ffffff;
                text-transform: uppercase;
                opacity: 1;
            }
        }
    }
    .next-page-btn {
        min-width: 60px;
        height: 42px;
        /* UI Properties */
        background: #3eabf0 0% 0% no-repeat padding-box;
        border-radius: 2px;
        border: 0;
        opacity: 1;
        span {
            height: 15px;
            /* UI Properties */
            text-align: right;
            font: normal normal bold 12px/15px Montserrat;
            letter-spacing: 0px;
            color: #ffffff;
            text-transform: uppercase;
            opacity: 1;
        }
    }

}
