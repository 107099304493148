.setScore-modal {
    .col {
        padding: 0;
        margin: 0;
    }
    .row {
        margin: 0px;
    }
    .E81232 {
        margin: 0px 0px 1rem 0px ;
        padding-top: 2px;
        padding-bottom: 2px;
    }
    // padding: 0px 0px 0 25px !important;

    h4 {
        margin-left: 5px;
    }
    .pd-20 {
        padding: 0 20px 0 20px !important;
    }
    .modal-close {
        padding: 24px 20px 0px 0px;
        // color: #ffff
    }
    .modal-header {
        // margin-top: 25px;
        padding: 20px 20px 0px 20px !important;

        // height: 22px;
        /* UI Properties */
        text-align: left !important;
        font: normal normal bold 18px/22px Montserrat;
        letter-spacing: 0px;
        color: #ffffff;
        text-transform: uppercase;
        opacity: 1;
        h2 {
            top: 75px !important;
        }
    }
    .footer-horizontal-line {
        padding: 15px 0 15px 0 !important;
        border-top: 1px solid #22262e;
        opacity: 1;
    }
    .modal-body {
        padding: 0 !important;
        input[type="number"] {
            width: 160px;
            height: 40px;
            /* UI Properties */
            border: 1px solid #22262e;
            border-radius: 2px;
            opacity: 1;
        }
        .scores-manually {
            // margin: 0px 0px 0px 35px;
            input[type="number"] {
                font-size: medium !important;
                font-weight: 300 !important;
                text-align: center;
            }
            input[type="checkbox"] {
                margin: 0;
                width: 20px;
                height: 20px;
                border-radius: 2px;
                opacity: 1;
                border: 1px solid #474d55;
                &:checked::after {
                    opacity: 1;
                    animation-name: fadeInOpacity;
                    animation-iteration-count: 1;
                    animation-timing-function: ease-in;
                    animation-duration: 90ms;
                    @keyframes fadeInOpacity {
                        0% {
                            opacity: 0;
                        }
                        100% {
                            opacity: 1;
                        }
                    }
                    border-radius: 2px;
                    width: 100%;
                    height: 100%;
                    content: url(../../../img/tick.svg);
                    color: white;
                    background-color: #3eabf0;
                    position: absolute;
                    font-size: 19px;
                }
            }

            button {
                width: 38px;
                height: 40px;
                transform: matrix(1, 0, 0, 1, 0, 0);
                /* UI Properties */
                background: #68717e20 0% 0% no-repeat padding-box;
                border: 0px solid #707070;
                // border: 1px solid #707070;
                border-radius: 2px 0px 0px 2px;
                opacity: 1;
                color: #ffffff;
            }
            .teams-scores {
                display: flex;
                align-items: center;
                text-align: center;
                .swap-btn {
                    flex: 0 0 60px;
                }
                .team-name-logo {
                    h5 {
                        padding-left: 5px !important;
                    }
                }
            }
        }
        .tournaments-codes-row {
            .t-codes-title {
                width: 139px;
                height: 15px;
                /* UI Properties */
                text-align: left;
                font: normal normal bold 12px/15px Montserrat;
                letter-spacing: 0px;
                color: #ffffff;
                opacity: 1;
            }
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .form-group {
                width: 100%;
            }
            .d-flex {
                display: flex;
                input {
                    width: 100%;
                }
            }
            .copy-code-button {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-content: stretch;
                justify-content: flex-start;
                align-items: center;
                // width: 70px;
                margin-top: 6px;
                /* UI Properties */
                background: #68717e 0% 0% no-repeat padding-box;
                border-radius: 0px 2px 2px 0px;
                opacity: 1;
                span {
                    width: 30px;
                    height: 13px;
                    /* UI Properties */
                    text-align: right;
                    font: normal normal bold 10px/13px Montserrat;
                    letter-spacing: 0px;
                    color: #ffffff;
                    text-transform: uppercase;
                    opacity: 1;
                }
            }
        }
    }
    .notes-div {
        .note {
            width: 35px;
            height: 15px;
            /* UI Properties */
            text-align: left;
            font: normal normal bold 12px/15px Montserrat;
            letter-spacing: 0px;
            color: #ffffff;
            opacity: 1;
            margin-bottom: 10px !important;
        }
        padding: 0;
        padding-bottom: 5px;
        input[type="text"] {
            width: 100%;
            height: 80px;
            /* UI Properties */
            border: 1px solid #22262e;
            border-radius: 2px;
            opacity: 1;
            font-size: small;
        }
        input::placeholder {
            width: 359px;
            height: 15px;
            /* UI Properties */
            text-align: left;
            font: normal normal normal 12px/15px Montserrat;
            letter-spacing: 0px;
            color: #ffffff;
            opacity: 0.5;
        }
    }

    .quick-actions {
        margin: 20px 0px 0px 0px;
        padding: 0px 0px 20px 0px;
        width: 100%;
        border-top: 1px solid #22262e;
        border-bottom: 1px solid #22262e;
        .generate {
            border-radius: 2px 0px 0px 2px;
            height: 30px;
            border: 0;
            /* UI Properties */
            color: #ffffff;
            background: #68717e 0% 0% no-repeat padding-box !important;
            border-radius: 2px;
            opacity: 1;
            span {
                text-align: right;
                font: normal normal bold 12px/15px Montserrat;
                letter-spacing: 0px;
                color: #ffffff;
                text-transform: uppercase;
                opacity: 1;
            }
        }
        .reset-match {
            height: 30px;
            border: 0;
            margin-left: 10px;
            /* UI Properties */
            background: #f11857 0% 0% no-repeat padding-box !important;
            color: #ffffff;
            border-radius: 2px;
            opacity: 1;
            span {
                text-align: right;
                font: normal normal bold 12px/15px Montserrat;
                letter-spacing: 0px;
                color: #ffffff;
                text-transform: uppercase;
                opacity: 1;
            }
        }
    }

    .modal-footer {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        padding: 0 0 15px 0px;
        .footer-buttons {
            display: flex !important;
            flex-wrap: nowrap;
            flex-direction: row !important;
            .save-changes-btn {
                width: 160px;
                height: 42px;
                border: 0;
                /* UI Properties */
                background: #3eabf0 0% 0% no-repeat padding-box;
                border-radius: 2px;
                opacity: 1;
                span {
                    width: 99px;
                    height: 15px;
                    /* UI Properties */
                    text-align: right;
                    font: normal normal bold 12px/15px Montserrat;
                    letter-spacing: 0px;
                    color: #ffffff;
                    text-transform: uppercase;
                    opacity: 1;
                }
            }
            .cancel-changes-btn {
                margin-left: 10px;
                height: 42px;
                /* UI Properties */
                background: transparent;
                border: 1px solid #22262e;
                border-radius: 2px;
                opacity: 1;
                span {
                    width: 52px;
                    height: 15px;
                    /* UI Properties */
                    text-align: right;
                    font: normal normal bold 12px/15px Montserrat;
                    letter-spacing: 0px;
                    color: #ffffff;
                    text-transform: uppercase;
                    opacity: 1;
                }
            }
        }
    }
}
.round-score-heading {
    height: 38px;
    width: 100%;
    /* UI Properties */
    background: #1f232b 0% 0% no-repeat padding-box;
    opacity: 1;
    margin: 0 0 10px 0 !important;
    h4 {
        margin: 0 0 0 20px !important;
    }
}
.round-score-row {
    width: 100%;
    .round-score-col-1 {
        flex: 0 0 25%;
        .game-capsule {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-content: center;
            justify-content: center;
            align-items: center;
            width: 73px;
            height: 27px;
            text-align: center;
            margin: auto;
            /* UI Properties */
            background: #68717e 0% 0% no-repeat padding-box;
            border-radius: 20px;
            opacity: 1;
            span {
                // width: 46px;
                height: 15px;
                /* UI Properties */
                text-align: left;
                font: normal normal bold 12px/15px Montserrat;
                letter-spacing: 0px;
                color: #ffffff;
                text-transform: uppercase;
                opacity: 1;
            }
        }
    }
    .round-score-col-team {
        flex: 0 0 25%;

        .team-name {
            // width: 46px;
            height: 15px;
            /* UI Properties */
            text-align: left;
            font: normal normal bold 12px/15px Montserrat;
            letter-spacing: 0px;
            color: #ffffff;
            opacity: 1;
            margin: 0 0 10px 0;
        }
        .team-side {
            // width: 79px;
            height: 15px;
            text-align: left;
            font: normal normal bold 12px/15px Montserrat;
            letter-spacing: 0px;
            color: #68717e;
            opacity: 1;
        }
    }
    .round-score-col-2 {
        // align-self: end;
        // margin-bottom: 5px;
        flex: 0 0 10%;
        text-align: center;
    }
    .scores-manually-2 {
        button {
            width: 38px;
            height: 40px;
            transform: matrix(1, 0, 0, 1, 0, 0);
            /* UI Properties */
            background: #68717e20 0% 0% no-repeat padding-box;
            border: 0px solid #707070;
            // border: 1px solid #707070;
            border-radius: 2px 0px 0px 2px;
            opacity: 1;
            color: #ffffff;
        }
        input {
            font-size: medium !important;
            font-weight: 300 !important;
            padding: 0 0 0 0 !important;
            text-align: center;
            width: 40px !important;
            max-width: 60px !important;
            height: 40px;
            transform: matrix(1, 0, 0, 1, 0, 0);
            /* UI Properties */
            background: #171a22 0% 0% no-repeat padding-box;
            border: 0px solid #707070;
            // border: 1px solid #707070;
            border-radius: 2px 0px 0px 2px;
            opacity: 1;
            color: #ffffff;
        }
    }
}
