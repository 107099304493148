.form-control.form-input {
    width: 80%;
    display: inline-block;
    margin-left: 1rem;
    outline: 0;
    appearance: none;
    border: 1px solid #22262e;
    transition: border 0.3s;
  
    &:focus {
      border-color: var(--primary) !important;
    }
  }
  
  .editCategory {
    label {
      font-weight: normal ;
      text-transform: capitalize !important;
      margin-left: 2px;
    }
    .team__item_details-game-name {
      color: #68717e;
    }
  
    .image-cover-category {
      position: absolute;
      z-index: 1;
      transform: rotate(45deg);
      top: 5px;
      left: 10px;
      width: 10px;
      height: 20px;
      background-color: #12141a;
    }
  
    .team-content {
      padding-top: 10px;
    }
  
    input {
      background-color: #0f1116 !important;
      border: 1px solid #3b3e45 !important;
    }
  }
  
  .modal-body {
    .badge {
      background-color: var(--primary);
      border-radius: 15px;
      padding: 3px 12px;
      margin-left: 15px;
    }
    .badge-secondary {
      background-color: #23272e;
    }
    .desc {
      font-size: 12px;
      margin-top: -5px;
    }
    .teams__container {
      max-height: 424px;
      overflow-y: scroll;
    }
    .team__item.row {
      margin: 5px 7px 5px 0;
      border-radius: 7px;
      // border: 1px solid lighten(gray, 0.5);
      border: 1px solid #3b3e45;
      height: 90px;
      cursor: pointer;
      .col-4 {
        padding: 5px;
        img {
          width: 100%;
          height: 100%;
          padding: 7px;
          object-fit: cover;
        }
      }
      .col-8 {
        padding-left: 10px;
        h3 {
          margin-bottom: 0.4rem;
          font-size: 14px;
          text-transform: uppercase;
        }
        .team__item_details {
          display: flex;
          justify-content: flex-start;
          font-size: 11px;
          align-items: center;
          .team__item_details-game {
            height: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            img {
              height: 15px;
              padding-right: 5px;
            }
          }
        }
      }
    }
  
    .team__item.row.selected {
      border-color: var(--primary);
    }
  }
  
  .modal-footer {
    .foot-note {
      font-size: 13px;
      color: #d0d0d1;
      margin: 0 20px 0 20px;
    }
  }
  a{
    text-decoration: none;
  }
  .nav-item{
    margin-right: 10px;

  }
  .nav-tabs{
    margin-bottom: 10px;
    display: flex;
  }
  .set-player-label{
    font: normal normal bold 12px/15px Montserrat;
  }