.modal-sharepop {
  width: 550px !important;

  .modal-body{
    overflow-x: auto !important; 
    .dynamic-search-selected-items{
      overflow-x: hidden;
    }
  }
}
.selected-item {
  &:hover {
    background: #22262e 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
  }
}
.no-select {
  &:hover {
    background-color: transparent;
  }
}
.user-container {
  margin: none !important;
  padding-top: 30px;
}
.sharepop-searchbar input {
  border: 1px solid #8b8d91 !important;
}
.sharepopup-tab nav {
  margin: 0px !important;
  padding-bottom: 0px !important;
  border-bottom: 0.1px solid #22262e !important;
}
.selected-user-delete {
  position: relative;
}
.share-popup-modalheader {
  border-bottom: none !important;
}
.share-popup-customize {
  padding-top: 30px;
}
.customize-item {
  padding: 6px 0px 6px 0px;
}
.customize-inner-heading {
  padding: 10px 0px 10px 0px;
}
.customize-item p {
  margin-top: auto;
  margin-bottom: auto;
}
.check-type2 p {
  margin: none !important;
}

.show-dropdown-arrow {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDUiIGhlaWdodD0iMjcuNSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsPSJub25lIiBkPSJNLTEtMWg0N3YyOS41SC0xeiIvPjxnPjxnIHN0cm9rZS1saW5lY2FwPSJ1bmRlZmluZWQiIHN0cm9rZS1saW5lam9pbj0idW5kZWZpbmVkIiBzdHJva2Utd2lkdGg9IjMiIHN0cm9rZT0iIzk5OSIgZmlsbD0ibm9uZSI+PHBhdGggZD0iTTMuNTU4IDMuNDI5bDIwLjU1OCAyMC41NTdNNDIuNjAzIDMuNDI5TDIyLjA0NiAyMy45ODciLz48L2c+PC9nPjwvc3ZnPg==);
  background-size: 10px 5px;
  background-position: calc(100% - 12px) 50%;
  background-repeat: no-repeat;
}
.visible {
  display: block;
}

.selected {
  background: #22262e 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
}
.dropdown-background-color {
  border-radius: 25px;
  width: 102px !important;
  background: #22262e !important;
  display: flex;
  font: normal normal bold 12px/15px Montserrat !important;
  align-items: center;
  justify-content: center;
  color: white !important;
  font-weight: 500;
  margin-right: 36px !important;
  border: 1px solid #22262e !important;
}


.sharepopup-select-option{
  .tippy-box{
    background-color: #0f1116;
    max-width: 450px !important;
    max-height: 360px;
    border-radius: 5px;
    // overflow-y: scroll; 
  }
  .tippy-content{
    max-height: 350px;
    padding: 0;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}