.pwt-single-header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin-left: 10%;

  .pwt-single-header-inner {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-end;
    width: 100%;
  }

  .button.cta-button {
    padding: 14px 26px;
    // , &:not(.lockAnimation)
    &:hover:not(.lockAnimation) {
      padding: 19px 31px;
    }
    //	font-size: 12px;
  }
}
