.dropdown {
  position: relative;
  display: flex !important;
  align-items: center;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  width: 170px;
  height: 40px;
  background-color: #12141A;
  // margin: auto;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: bold;
}

.dropdown-content {
  position: absolute;
  background-color: #fff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: 170px;
  /* width: fit-content; */
  margin-top: 5px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 5px;
  overflow: auto;
}

.option-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 10px;
}
/* .option-item:hover {
  color: #fff;
  background-color: #2c8bf1;
  cursor: pointer;
} */

.disabled-option-wrapper {
  opacity: 0.3 !important;;
  pointer-events: none;
  // background: rgba(225, 225, 225, 0.7) !important;
  border-radius: 0.025rem;
}

.hovered {
  color: #fff;
  background-color: #2c8bf1;
  cursor: pointer;
}

.down-arrow-opt {
  position: absolute;
  height: 10px;
  top: 12px;
  right: 5px;
}

.no-spot {
  .dropdown, .greyed-text-dd {
    background-color: rgba(18, 20, 26, 0.3);
    color: rgba(255, 255, 255, 0.3);
  }
}
.dropdown-fix {
  padding: 0;
  max-height: 35px;
  width: auto !important;
  border: 1px solid #22262e;
  &:hover {
    border: 1px solid #68717e;
  }
}

.dropdown-button-fix {
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
}
.dropdown-menu {
  &.show {
    width: 195px;
    max-height: 400px;
    overflow-y: scroll;
    background-color: #000;
  }
}
.dropdown-item-fix {
  min-width: 100%;
  display: flex;
  padding-left: 1em;
  padding-top: .7em;
  padding-bottom: .7em;
  &:hover {
    background-color: #1c2029;
  }
}

.timezone-text {
  color: #FFF;
  font-size: .8rem;
  font-weight: 700;
}