.selectionBarContainer {
  margin-top: 10px;
  width: 100%;
  height: 65px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #22262e;
}
.selectActionsDropdown {
  text-transform: uppercase;
  width: 250px !important;
  margin: 0px 15px;
  border: 1px solid #57595f !important;

}
.sepratorline {
  color: #68717e;
  font-size: xx-large;
}
.applyButton{
    margin: auto;
    margin-right: 10px;
    margin-left: auto !important;

}
.moveMatchContainer{
    display: flex;
    width: 65%;
    height: 45px;
    align-items: center;
    margin: 0px 15px;
}
.inputNumber{
    width:200px !important;
    border: 1px solid #57595f !important;
    margin: 0px 10px;
}
.plusminusbutton{
    width: 38px;
    height: 48px;
    transform: matrix(1, 0, 0, 1, 0, 0);
    background: #68717e20 0% 0% no-repeat padding-box;
    border: 0px solid #707070;
    border-radius: 2px 0px 0px 2px;
    opacity: 1;
    color: #ffffff;
}
