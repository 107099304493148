.share-options-O {
    // background: #0f1116 0% 0% no-repeat padding-box;
    // border: 1px solid #22262e;
    border-radius: 2px;
    padding: 7px;
    opacity: 1;
    // padding-left: 6px;
    //width height
    // width: 80px;
    // height: 70px;
    .option {
        cursor: pointer;
        font-weight: 400;
        padding: 4px 0px 4px 0px;
        text-align: left;
        font: normal normal bold 12px/15px Montserrat;
        letter-spacing: 0px;
        color: #ffffff;
        text-transform: uppercase;
        opacity: 1;
    }
}

//THese are styles for context-menu classes present in ContextMenu.jsx
.context-menu-11 {
    min-width: 74px;
    height: auto;
    position: fixed;
    background: #0f1116 0% 0% no-repeat padding-box;
    border: 1px solid #22262e;
    border-radius: 2px;
    z-index: 100;
    padding: 4px;
}
.context-menu-fade-in {
    opacity: 1;
    transition: opacity 400ms linear;
}
