.stages-component-styles{
.stage-tab-custom{
  label{
    pointer-events: all !important;
  }
}
// input[type="number"]:disabled{
  // color: #8d8d8d !important;
  // background-color:#151515 !important;}

  .black-bg {
    // background-color: #02060d !important;
    background-color: #02060e !important;
    border: 1px solid #22262e !important;
    border-radius: 2px;
    opacity: 1;
    &:hover {
      border: 1px solid var(--primary) !important;
    }
  }
  .grey-bg {
    background: #12141a 0% 0% no-repeat padding-box !important;
    border: 1px solid #22262e !important;
    border-radius: 2px;
    opacity: 1;
    &:hover {
      border: 1px solid var(--primary) !important;
    }
  }
  .red-bg {
    background: #12141a 0% 0% no-repeat padding-box !important;
    border: 1px solid #f11857 !important;
    border-radius: 2px;
    opacity: 1;
    &:hover {
      border: 1px solid var(--primary) !important;
      // border: 1px solid #d5901f !important;
    }
  }
  .left-row-stages {
    // input{
    //     color: var(white) !important;
    // }
    .stage-fields-pos {
      padding-bottom: 20px !important;
      padding-left: 0px !important;
    }
  }
  .btn-preview-brackets {
    width: 196px;
    height: 42px;
    /* UI Properties */
    background: #22262e 0% 0% no-repeat padding-box;
    border-radius: 2px;
    opacity: 1;
  }
  #flexCheckChecked {
    margin: 0;
    width: 20px;
    height: 20px;
    border-radius: 2px;
    opacity: 1;
    border: 1px solid #474d55;
    &:checked::after {
      opacity: 1;
      animation-name: fadeInOpacity;
      animation-iteration-count: 1;
      animation-timing-function: ease-in;
      animation-duration: 90ms;
      @keyframes fadeInOpacity {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }

      width: 100%;
      height: 100%;
      content: url(./../../.././../.././img/tick.svg);
      color: white;
      // right: 7px;
      border-radius: 2px;
      background-color: #3eabf0;
      // left: 0px;
      position: absolute;
      // top: 3px;
      font-size: 19px;
    }
  }
  // #flexCheckChecked::before{
  // background-color: #03060d !important;
  // }
  .form-check {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
  }
  .form-check-label {
    margin-top: auto !important;
    margin-bottom: auto !important;
    margin-left: 5px !important;
  }
  table {
    border-collapse: collapse;
    tr {
      border-bottom: 1px solid #1d1f26 !important;
    }
    td {
      // position: relative!important;
      padding: 8px 20px 8px 2px !important;
    }
  }
  .input-red-border-1{
    border: 1px solid  #d5901f !important;
    &:hover{
    border: 1px solid  #d5901f !important;
    }
  }
  .input-red-border-2{
    border: 1px solid  #f11857 !important;
    &:hover{
    border: 1px solid  #f11857 !important;
    }
  }

  // COLORS DROPDOWN
  // Green - #19db60
  // Yellow - #d5901f
  // Blue - #3eabf0
  // Red - #f11857
  // Cyan - #88d1ff
  // Orange - #d14419
  // Malachite - #19d17c
  // Magenta - #e23ef0
  // Kiwi - #a6f118
  // Gray - #7e8fa8

  .colors-dropDown {
    z-index: 9999;
    position: absolute;
    // display: none;
    padding: 10px 18px 0px 10px !important;
    width: 208px !important;
    height: 101px !important;
    /* UI Properties */
    background: #12141a 0% 0% no-repeat padding-box;
    border-radius: 2px;
    opacity: 1;
    .colors-div {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-content: flex-start;
    }
    .clrPic {
      margin: 3px;
    }
    .clr-custom-button {
      margin-top: 15px;
      width: 62px;
      height: 24px;
      /* UI Properties */
      text-align: left;
      font: normal normal bold 10px/13px Montserrat;
      letter-spacing: 0px;
      color: #ffffff;
      background: #68717e 0% 0% no-repeat padding-box;
      text-transform: uppercase;
      opacity: 1;
    }
    .color-Green {
      width: 16px;
      height: 16px;
      /* UI Properties */
      background: #19db60 0% 0% no-repeat padding-box;
      border-radius: 4px;
      opacity: 1;
    }
    .color-Yellow {
      width: 16px;
      height: 16px;
      /* UI Properties */
      border-radius: 4px;
      opacity: 1;
      background: #d5901f 0% 0% no-repeat padding-box;
    }
    .color-Blue {
      width: 16px;
      height: 16px;
      /* UI Properties */
      border-radius: 4px;
      opacity: 1;
      background: #3eabf0 0% 0% no-repeat padding-box;
    }
    .color-Red {
      width: 16px;
      height: 16px;
      /* UI Properties */
      border-radius: 4px;
      opacity: 1;
      background: #f11857 0% 0% no-repeat padding-box;
    }
    .color-Cyan {
      width: 16px;
      height: 16px;
      /* UI Properties */
      border-radius: 4px;
      opacity: 1;
      background: #88d1ff 0% 0% no-repeat padding-box;
    }
    .color-Orange {
      width: 16px;
      height: 16px;
      /* UI Properties */
      border-radius: 4px;
      opacity: 1;
      background: #d14419 0% 0% no-repeat padding-box;
    }
    .color-Malachite {
      width: 16px;
      height: 16px;
      /* UI Properties */
      border-radius: 4px;
      opacity: 1;
      background: #19d17c 0% 0% no-repeat padding-box;
    }
    .color-Magenta {
      width: 16px;
      height: 16px;
      /* UI Properties */
      border-radius: 4px;
      opacity: 1;
      background: #e23ef0 0% 0% no-repeat padding-box;
    }
    .color-Kiwi {
      width: 16px;
      height: 16px;
      /* UI Properties */
      border-radius: 4px;
      opacity: 1;
      background: #a6f118 0% 0% no-repeat padding-box;
    }
    .color-Gray {
      width: 16px;
      height: 16px;
      /* UI Properties */
      border-radius: 4px;
      opacity: 1;
      background: #7e8fa8 0% 0% no-repeat padding-box;
    }
  }

  // GROUP COLOR DROPDOWN STYLING
  .grp-color-dropDown {
    background: #12141a 0% 0% no-repeat padding-box !important;
    transition: 0.3s;
    border-radius: 2px;
    opacity: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: stretch;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 100%;
    &:hover {
      background: #0d0f13 0% 0% no-repeat padding-box !important;
    }
    p {
      padding: 0px 0px 0px 5px;
    }
    span {
      float: right;
    }
    .clr-Pic {
      margin: 3px;
    }

    .color-Green {
      width: 16px;
      height: 16px;
      /* UI Properties */
      // background: #19db60 0% 0% no-repeat padding-box;
      border-radius: 4px;
      opacity: 1;
    }
  }

  .preview-groups {
    .span-preview-name {
      text-align: center;
      font: normal normal bold 10px/13px Montserrat;
      letter-spacing: 0px;
      // color: #ffffff;
      opacity: 1;
    }
    width: 69px;
    height: 23px;
    text-align: center;
    /* UI Properties */
    // background: #19db6022 0% 0% no-repeat padding-box;
    border-radius: 13px;
    opacity: 1;
  }
  .visible {
    display: block !important;
  }
  .add-group-btn {
    float: right; // for moving it to right position
    margin-top: 15px;
    width: 125px;
    height: 30px;
    color: white !important;
    text-transform: uppercase;
    // margin-top: -1vh;
    font-size: x-small;
    font-weight: bold;
    padding: 5px 5px 5px 5px !important;
    /* UI Properties */
    background: #68717e 0% 0% no-repeat padding-box !important;
    border-radius: 2px;
    opacity: 1;
  }
  .sketch-picker {
    background: #12141a 0% 0% no-repeat padding-box !important;
    label {
      color: white !important;
    }
  }
  .group-scrollbar {
    overflow-y: scroll;

    max-height: 290px;
  }
  div::-webkit-scrollbar {
    display: block !important;
    width: 10px;
    z-index: 99;
  }
  div::-webkit-scrollbar-track {
    background-color: #191b24;
  }
  div::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #22262e;
  }

  /// for dissapearing number arrow

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    appearance: textfield;
    -moz-appearance: textfield;
  }
  .disabled-field-stages {
    display: hidden !important;
  }
  .side-buttons {
    button {
      color: white !important;
    }
    .button-primary {
      width: 133px;
      height: 42px;
      /* UI Properties */
      background: #3eabf0 0% 0% no-repeat padding-box;
      border-radius: 2px;
      font-size: bold !important;
      opacity: 1;
      border: 0 !important;
    }
    .button-disabled {
      width: 133px;
      height: 42px;
      margin-right: 10px !important;
      /* UI Properties */
      background: #68717e 0% 0% no-repeat padding-box;
      border-radius: 2px;
      font-size: bold !important;
      opacity: 1;
      border: 0 !important;
    }
    .button-delete {
      background-color: #f11857 !important;
      border-radius: 2px;
      opacity: 1;
      width: 128px;
      height: 42px;
      font-size: bold !important;

      padding: inherit !important;
      border: 0 !important;
    }
    // .preview-brackets-btn {
    //   background-color: var(#68717e) !important;
    // }
  }

  /////////////TABLE STYLING /////////////////
  .fixed-header {
    width: auto;
    table-layout: fixed;
    border-collapse: collapse;

    tbody {
      display: block;
      overflow: auto;
      max-height: 300px;
    }
    thead,
    tr {
      display: block;
    }
    thead {
      // background: black;
      color: #fff;
    }

    th,
    td {
      padding: 5px;
      text-align: left;
      width: 200px;
    }
  }

  /// END HERE//////////////////////
  .label-left {
    justify-content: flex-start !important;
  }
  *:disabled {
    background-color: #12141a !important;
    color: #717276 !important;
    opacity: 1;
    &:hover {
      border: 1px solid #22262e !important;
    }
  }
}
  ///////////////////////////////////
  .brackets-class {
    * {
      min-height: auto;
      min-width: auto;
    }

    &.bg-theme {
      background-color: #03060d;
      color: #ffffff;
    }

    .bg-hilighted {
      background-color: #2c313a;
    }

    .bg-force-hilight {
      background-color: #2c313a;
    }

    .bg-hilighted-lost {
      background-color: #ab4a58;
    }

    .bg-lost {
      // opacity: 0.5;
      background-color: #0b0e12;
    }

    .align-items-center {
      align-items: center;
    }

    .w-100 {
      width: 100%;
    }

    .h-100 {
      height: 100%;
    }

    .flex-fill {
      flex: 1 1;
    }

    .seed-container {
      border-radius: 10px;
    }

    .seed-item {
      min-height: 40px;
    }

    .visibility-hidden {
      visibility: hidden !important;
    }

    .margin-bottom-7 {
      margin-bottom: 6px !important;
    }

    .position-relative {
      position: relative;
    }

    .zoom-control-buttons {
      position: absolute;
      top: 0px;
      right: 0px;
    }

    .zoom-control-button {
      margin: 20px;
      background-color: #000000;
      border: 1px solid #777777;
      border-radius: 3px;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
    }
  }

  div:focus {
    outline: none;
  }
  .single-bracket + .single-bracket {
    margin-top: 70px;
  }
  .tag-team-round-matches {
    padding: 0 10px 0 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    height: 24px;
    max-width: 120px;
    margin-left: 5px;
    /* UI Properties */
    background: #22262e 0% 0% no-repeat padding-box;
    border-radius: 100px;
    opacity: 1;
    .num {
      height: 13px;
      /* UI Properties */
      text-align: left;
      font: normal normal bold 10px/13px Montserrat;
      letter-spacing: 0px;
      color: #ffffff;
      span {
        color: #68717e;
        text-transform: uppercase;
        margin-right: 7px;
      }
    }
  }
  .E569 {
    background-color: #121419 !important;
}
.E748 {
  background-color: #1b1e28 !important;
}
.E6565 {
  font-weight: bold;
  color: #68717e !important;
}

