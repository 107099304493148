.c-multi-drag-table.is-dragging table {
  border: 1px solid #4285f4;
}
.c-multi-drag-table .ant-col {
  position: relative;
}
.c-multi-drag-table .ant-col:first-child:after {
  display: block;
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  width: 1px;
  height: 100%;
  background: #e2e2e2;
  z-index: 1;
}
.c-multi-drag-table .inner-col {
  position: relative;
  height: 100%;
  background: #fff;
  border: 1px solid #f0f0f0;
  padding: 10px;
  /* z-index: 2; */
}
.c-multi-drag-table .ant-table {
  border: 1px solid #f0f0f0;
}
.c-multi-drag-table .ant-table-thead > tr > th,
.c-multi-drag-table .ant-table-tbody > tr > td,
.c-multi-drag-table .ant-table tfoot > tr > th,
.c-multi-drag-table .ant-table tfoot > tr > td {
  padding: 6px 16px;
}
.c-multi-drag-table .ant-table-tbody > tr.ant-table-placeholder > td,
.c-multi-drag-table .ant-table-tbody > tr.ant-table-placeholder:hover > td {
  background: transparent;
}
.c-multi-drag-table .ant-table-tbody > tr.row-item {
  background: #fff;
}
.c-multi-drag-table .ant-table-tbody.is-dragging-over > tr.ant-table-placeholder {
  background: #deebff !important;
}
.c-multi-drag-table .ant-table-tbody > tr.row-item.row-selected {
  background: #deebff;
}
.c-multi-drag-table .ant-table-tbody > tr.row-item.row-dragging {
  display: table;
  color: #fff;
  background: #4285f4;
  border: 1px solid #f0f0f0;
}
.c-multi-drag-table .ant-table-tbody > tr.row-item.row-dragging > td {
  border-bottom: 0;
}
.c-multi-drag-table .ant-table-tbody > tr.row-item.row-ghosting {
  opacity: 0.5;
}
.selected-row {
  background-color: #deebff;
}

.ghosting-color {
  display: none !important;
}
table {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}
.folder-1 {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  border: 1px solid #3eabf078;
  color: var(--primary);
  border-radius: 8px;
  // width: 320px;
  width: 280px;

  // display: inline-block;
  margin: 5px 20px 25px 0;
  font-weight: 500;
  // text-transform: uppercase;
  &:hover {
    border: 1px solid var(--primary) !important;
    // box-shadow: 4px 4px red;
  }
}
.activeDots {
  .hi-dots {
    color: #3eabf0;
    background-color:  #091625;
  }
}
.card-panel-1 {
  display: flex;
  flex-direction: row;
  place-content: space-between;
  height: 50px;
  padding: 15px 20px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
}
.selected-folder {
  border: 1px solid var(--primary) !important;
  background-color: #050c16;
}
.selected-folder .Dots-container .hi-dots{
  color: #3eabf0 !important;
  background-color: #091625  !important;
}
.remove-tab{
  &:focus { outline: none !important; }
}