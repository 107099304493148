//additional Styles specific to this componentCard will be added here

.competitionCard-mainDiv {
    width:280px; //For the Size of Competition Item.
    .parent-of-component {
        display: flex;
        // padding: 1px 0px 0px 5px;
        flex-direction: row;
        align-items: center;
        flex-wrap: nowrap;
        .logo-CC {
            align-items: center;
            display: flex;
            img {
                width: 20px;
                height: 20px;
            }
        }
        .item-title-CC {
            -webkit-user-select: text;
            -moz-user-select: text;
            -o-user-select: text;
            user-select: text;
            display: flex;
            align-items: center;
            padding-left: 5px;
            text-align: left;
            h3 {
                text-overflow: ellipsis;
                max-width: 198px;
                white-space: nowrap;
                font-size: 12px;
                margin: 0px;
                min-width: 159px;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: left;
                font: normal normal bold 12px/15px Montserrat;
                letter-spacing: 0px;
                color: #8fa3b1;
                text-transform: uppercase;
                opacity: 1;
            }
        }
    }
}
