.ghost-content {
  .content {
    width: 70%;
    margin: 0 auto;
  }

  img {
    display: block;
    max-width: 100%;
  }

  .kg-width-wide {
    position: relative;
    width: 85vw;
    min-width: 100%;
    margin: auto calc(50% - 50vw);
    transform: translateX(calc(50vw - 50%));
  }

  .kg-width-full {
    position: relative;
    width: 100vw;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
  }

  article figure {
    margin: 0;
  }

  article figcaption {
    text-align: center;
  }

  body {
    margin: 0;
  }

  header,
  footer {
    padding: 15px 25px;
    background-color: #000;
    color: #fff;
  }
  .kg-gallery-container {
    display: flex;
    flex-direction: column;
    margin: 1.5em auto;
    max-width: 1040px;
    width: 100vw;
  }

  .kg-gallery-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .kg-gallery-image img {
    display: block;
    margin: 0;
    width: 100%;
    height: 100%;
  }

  .kg-gallery-row:not(:first-of-type) {
    margin: 0.75em 0 0 0;
  }

  .kg-gallery-image:not(:first-of-type) {
    margin: 0 0 0 0.75em;
  }

  .kg-bookmark-card {
    width: 100%;
    position: relative;
  }

  .kg-bookmark-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    color: currentColor;
    font-family: inherit;
    text-decoration: none;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }

  .kg-bookmark-container:hover {
    text-decoration: none;
  }

  .kg-bookmark-content {
    flex-basis: 0;
    flex-grow: 999;
    padding: 20px;
    order: 1;
  }

  .kg-bookmark-title {
    font-weight: 600;
  }

  .kg-bookmark-metadata,
  .kg-bookmark-description {
    margin-top: 0.5em;
  }

  .kg-bookmark-metadata {
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .kg-bookmark-description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }

  .kg-bookmark-icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    vertical-align: text-bottom;
    margin-right: 0.5em;
    margin-bottom: 0.05em;
  }

  .kg-bookmark-thumbnail {
    display: flex;
    flex-basis: 24rem;
    flex-grow: 1;
  }

  .kg-bookmark-thumbnail img {
    max-width: 100%;
    height: auto;
    vertical-align: bottom;
    object-fit: cover;
  }

  .kg-bookmark-author {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .kg-bookmark-publisher::before {
    content: '•';
    margin: 0 0.5em;
  }

  .text-upper {
    text-transform: uppercase;
  }
}
