.row {
  align-items: center;
}
table {
  width: 100%;
}
th {
  text-align: left;
  height: 47px;
  font: normal normal bold 12px/15px Montserrat;
  letter-spacing: 0px;
  color: #68717e;
  text-transform: uppercase;
  opacity: 1;
}
td {
  height: 47px;
  width: 13%;
}
.hoverable-row:hover {
  /* UI Properties */
  background: #0a0d13 0% 0% no-repeat padding-box;
  opacity: 1;
}
.parent-div {
  height: 47px;
  &:hover {
    /* UI Properties */
    background: #0a0d13 0% 0% no-repeat padding-box;
    opacity: 1;
  }
}
.main-div {
  background: #03060d 0% 0% no-repeat padding-box;
  opacity: 1;
  height: 48px;
  &:hover {
    /* UI Properties */
    height: 48px;
    background: #0a0d13 0% 0% no-repeat padding-box;
    opacity: 1;
  }
}
.checkbox-parent {
  padding-left: 0 !important;
  text-align: left;
  // width: 16px;
  // height: 16px;
}
.checkbox-row {
  /* UI Properties */
  width: 16px;
  height: 16px;
  border: 1px solid #474d55;
  border-radius: 2px;
  opacity: 1;
  background-color: black;
  background: transparent;
}
.name-row {
  text-align: left;
  font: normal normal bold 12px/15px Montserrat;
  letter-spacing: 0px;
  color: #8fa3b1;
  opacity: 1;
}
.org-name {
  text-align: center;
  font: normal normal bold 10px/13px Montserrat;
  letter-spacing: 0px;
  color: #8fa3b1;
  opacity: 1;
}
.me-organizer {
  // width: 19px;
  padding-left: 0 !important;
  height: 15px;
  /* UI Properties */
  text-align: left !important;
  font: normal normal normal 12px/15px Montserrat;
  letter-spacing: 0px;
  color: #68717e;
  opacity: 1;
}
.last-modified {
  width: 97px;
  height: 15px;
  text-align: left;
  font: normal normal normal 12px/15px Montserrat;
  letter-spacing: 0px;
  color: #68717e;
  opacity: 1;
  padding-left: 0 !important;
}
.options-dots {
  // width: 26px;
  height: 26px;
  padding-left: 0px !important;
  text-align: left;
  /* UI Properties */
  //   background: #68717e 0% 0% no-repeat padding-box;
  //   opacity: 0.15;
  .dots-inside {
    background: #68717e 0% 0% no-repeat padding-box;
    opacity: 0.15;
  }
}
.hi-dots {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: rgb(32, 30, 30);
  color: #68717e;
}

.text {
  display: block;
  width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}