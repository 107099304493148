.sw-container {
    /*text-align: center;*/
    text-transform: uppercase;
    font-weight: bold;
    color: #68717e;
    margin-right: 15px !important;
    }
    .toggle-switch {
    position: relative;
    width: 45px;
    display: inline-block;
    text-align: left;
    top: 8px;
    margin-right: 10px;
    }
    .checkbox {
    display: none;
    }
    .sw-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid #bbb;
    border-radius: 20px;
    }
    .inner {
    display: block;
    display: flex;
    align-items: center;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
    }
    .inner:before,
    .inner:after {
    float: left;
    width: 50%;
    height: 30px;
    padding: 0;
    line-height: 30px;
    color: #fff;
    font-weight: bold;
    box-sizing: border-box;
    }
    .inner:before {
    content: "";
    padding-left: 10px;
    background-color: #3eabf0;
    color: #fff;
    }
    .inner:after {
    content: "";
    padding-right: 10px;
    background-color: #11141B;
    color: #fff;
    text-align: right;
    }
    .switch {
    display: block;
    width: 25px;
    height: 25px;
    margin: 2.1px;
    background: #68717E;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 20px;
    border: 0 solid #68717E;
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;
    }
    .switch::after {
        background: #fff;
        border: 0 solid #fff;
    }
    .checkbox:checked + .sw-label .inner {
    margin-left: 0;
    }
    .checkbox:checked + .sw-label .switch {
    right: 0px;
    }    
    .stages-toggle{
        display:flex !important;
        align-items: center !important;
    }
    .stages-toggle::before,
    .stages-toggle::after{
        width: 100% !important;
    }
    .is-on::before{
        padding-left: 45px;
    }