.s-lable {
    color: rgb(104, 113, 126);
    font: bold 12px / 15px Montserrat;
}
.d-flex {
    display: flex !important;
}
.align-item-center {
    align-items: center !important;
}
.align-item-baseline {
    align-items: baseline !important;
}
.width-30 {
    width: 45% !important;
}
.mt-5 {
    margin-top: 5px;
}
.mt-30 {
    margin-top: 30px;
}
.add-on {
    margin-right: -3px;
    font-size: 12px;
    text-align: center;
    align-content: center;
    align-items: center;
    width: 70%;
    color: #FFFFFF;
    margin-top: 5px;
    line-height: 3.5;
    background-color: #12141A;
    border-top: 2px solid #22262e;
    border-right: 2px solid #22262e;
    border-bottom: 2px solid #22262e;
    height: 46px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
}
.border-bottom-0 {
    border-bottom: 0px;
}