.global-pnf {
  margin-top: 22px;
  padding-top: 22px;
  padding-bottom: 22px;

  .area-logo {
    display: flex;
    align-items: center;
    margin: 36px 25px;

    .menu-icon {
      appearance: none;
      background: none;
      border: 0;
      outline: 0;
      color: white;
      font-size: 1.5rem;
      width: 60px;
      height: 60px;
      margin-left: -12px;
      cursor: pointer;
      transition: 0.2s ease-in-out;
    }

    a.logo-text {
      font-size: 2.05rem;
      color: white;
      text-decoration: none;
      display: flex;
      align-items: center;

      img {
        margin-top: -10px;
        margin-left: 4px;
        margin-right: 12px;
        height: 60px;
        width: auto;
      }
    }
  }

  .container-fluid {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .copy-notice {
    padding: 12px 0px 36px;
    color: $text-faded-color;
    font-size: 0.75;
    font-weight: 700;
    text-transform: uppercase;
  }

  .footer-links a {
    color: $text-faded-color;
    text-transform: uppercase;
    font-weight: bold;
    margin: 0px 16px;
    text-decoration: none;
    padding: 16px 0px;
  }

  .icon {
    margin: 36px 25px;
    display: inline-block;

    a {
      height: 30px;
      width: 30px;
    }
  }
}
