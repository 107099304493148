.checkbox-wrapper-1 {
  .icon {
    margin-left: 10px;
    text-align: center;
    margin-bottom: 4px;
    background-color: #373b40;
    border-radius: 50%;
    width: 20px;

    .tooltiptext {
      visibility: hidden;
      width: auto;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      padding: 5px 0;
      /* Position the tooltip */
      position: absolute;
      z-index: 1;
      font-weight: normal;
      font-size: 10px;
      background-color: #535a65;
    }

    &:hover .tooltiptext {
      visibility: visible;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .checkbox-container-permission {
    border: none;
    width: 18px;
    height: 18px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid #4f5769;
    border-radius: 2px;
    margin-right: 10px;
  }

  .bgColor{
    background-color: var(--primary);
  }

  .permission {
    font-weight: bold;
    font-size: 12px !important;
  }

  .checkbox-container-1 {
    width: 15px;
    height: 15px;
    border-radius: 2px;
    margin-right: 10px;
    margin-left: 10px;
    // border: 1px solid #4f5769;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #091623;
    margin-top: .6%;
  }
  .checkbox-container-trainingBorder {
    width: 20px;
    height: 20px;
    border-radius: 2px;
    margin-right: 10px;
    // margin-left: 10px;
    border: 1px solid #68c5ff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #091623;
  }
  .checkbox-container-matchesBorder {
    width: 20px;
    height: 20px;
    border-radius: 2px;
    margin-right: 10px;
    // margin-left: 10px;
    border: 1px solid #d5901e;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #091623;
  }
  .checkbox-container-eventsBorder {
    width: 20px;
    height: 20px;
    border-radius: 2px;
    margin-right: 10px;
    // margin-left: 10px;
    border: 1px solid #46db60;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #091623;
  }
  .checkbox-container-trainings {
    width: 20px;
    height: 20px;
    border-radius: 2px;
    margin-right: 10px;
    // margin-left: 10px;
    border: 1px solid #4f5769;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #68c5ff;
  }
  .checkbox-container-matches {
    width: 20px;
    height: 20px;
    border-radius: 2px;
    margin-right: 10px;
    // margin-left: 10px;
    border: 1px solid #4f5769;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #d5901e;
  }
  .checkbox-container-events {
    width: 20px;
    height: 20px;
    border-radius: 2px;
    margin-right: 10px;
    // margin-left: 10px;
    border: 1px solid #4f5769;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #46db60;
  }

  .check-mark-1 {
    width: 10px;
    height: 10px;
  }

  .check-label-1 {
    text-transform: uppercase;
    font-size: 13px;
  }

  .trainings {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 13px;
  }
}

.check-type1 {
  .checkbox-container-1 {
    border: none;
    width: 18px;
    height: 18px;
    background-color: rgba(62, 171, 240, 0.1);
  }
  .checkbox-csgo-1 {
    background-color: rgba(229, 92, 19, 0.1)
  }
  .check-label-1 {
    font-weight: bold;
  }
}