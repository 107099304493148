.sortable-list {
  .sortable-list-header {
    padding-left: 8px;
    padding-right: 8px;
  }

  .sortable-list-header,
  .sortable-list-header button {
    font-size: 12px;
    line-height: 15px;
    color: $text-faded-color;
    text-transform: uppercase;
    font-weight: bold;
  }

  .sortable-list-header button {
    background-color: transparent;
    border: none;
    padding: 0px;
    margin: 0px;
    white-space: nowrap;
    font-size: 12px;
    line-height: 15px;
  }

  .sortable-list-header {
    .col-1,
    .col-2,
    .col-3,
    .col-4,
    .col-5,
    .col-6 {
      display: flex;
      align-items: center;
      font-size: 12px;
      line-height: 15px;
      font-weight: bold;
      text-transform: uppercase;

      &.right {
        justify-content: flex-end;
      }
      &.center {
        justify-content: center;
      }
      &.left {
        justify-content: flex-start;
      }
    }
  }

  ul.list li {
    &:first-child,
    &:last-child {
      border-top: 1px solid $tab-border-color;
    }

    .col-1,
    .col-2,
    .col-3,
    .col-4,
    .col-5,
    .col-6 {
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 18px;
      font-weight: bold;
      text-transform: uppercase;

      &.right {
        justify-content: flex-end;
      }
      &.center {
        justify-content: center;
      }
      &.left {
        justify-content: flex-start;
      }
    }
  }

  .image-40 {
    width: 40px;
    height: 40px;
    margin-right: 8px;
  }
}

.sortable-expandable-content {
  padding: 0 1rem 0 1rem;
  overflow: hidden;
  max-height: 0px;
  opacity: 0;
  transition: opacity 0.3s, max-height 0.3s;

  &.show {
    opacity: 1;
    max-height: 500px;
  }
}
