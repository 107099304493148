.aa {
  border: 2px solid red !important ;
}
.aa:focus-visible {
  outline: 1px solid red !important;
  border-radius: 3px;
}

.mainProgress {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.progressbarContainer {
  display: flex;
  flex-direction: row;
  /* align-content: center; */
  align-items: center;
  /* opacity: 50%; */
}
.progressbarCircle {
  width: 20px;
  height: 20px;
  /* border: 1px solid white; */
  background: #3eabf0;
  border-radius: 50%;
  box-shadow: -1px -1px 5px var(--primary), 1px -1px 5px var(--primary), -1px 1px 5px var(--primary),
    1px 1px 5px var(--primary);
}
.progressbarLine {
  width: 80px;
  border: 1px solid #3eabf0;
  height: 0px;
}
.progressbarText {
  position: absolute;
  margin: 50px 0px 0px -60px;
  color: #3eabf0;
}
.modal-header {
  /* background: linear-gradient(to top, rgb(3, 6, 13) 20%, rgba(3, 6, 13, 0) 120%), url(https://pr.leagues.gg/content/images/2022/10/Beacon.jpg); */
  text-align: left;
  /* position: absolute; */
  top: 0;
  left: 0;
  /* width: 100%; */
  /* height: 300px; */
  height: auto;
  background-size: cover !important;
  /* background-position: 50%!important;*/
}
.modal {
  width: 30%; /*removed important bcz its causing issue with editImageModal*/
}
.modal-close {
  right: 0px !important;
}

.margenedHeading {
  text-align: center !important;
  margin-top: 10%;
}
.totalCounts {
  height: fit-content;
  background: rgba(25, 219, 96, 0.133);
  font-size: 10px;
  color: white;
  font-weight: bold;
  border-radius: 30px;
  text-transform: uppercase;
  margin: 3px;
  /* width: auto; */
  text-align: center;
  cursor: default;
  justify-content: center;
  padding: 7px 10px;
}
h2 {
  margin-top: 0px;
  text-transform: uppercase;
}
.mainModalContent {
  display: flex;
  justify-content: space-between;
}
.ant-checkbox-input {
  width: 30px;
  height: 30px;
}
.create-button {
  max-width: 150px;
  float: right;
}
