.slider-item {
  &.frontend {
    height: 10%;
    width: 200px;
    position: relative;
    .item-image {
      // opacity: 0.4;
    }
    &:hover {
      // border: 1px solid var(--primary);
    }
  }

  // .item-image {
  //     height: 102px;
  // }
}

.smallSliderGameSelection {
  .slider-item {
    .item-image {
      height: 102px;
    }
  }
}
