.bannerContainer {
  display: flex;
  flex-direction: row;
  margin: 10px 10px 10px 10px;
  .logoSide, .logoDisabled {
    width: 5.6%;
    height: fit-content;
    .pencil {
      display: none;
      position: absolute;
      top: 13px;
      left: 80px;
      margin: 5px;
      background: #68717e;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      .pencilClass {
        margin-left: 3px;
        margin-top: 2px;
      }
    }
    .pencil:hover {
      border: none;
    }
    .bannerlogo {
      width: 100%;
      border-radius: 49%;
      background: transparent;
    }
    .bannerlogo:hover {
      border: none;
    }
  }
  .logoSide:hover {
    border: 2px dashed #22262e;
    .pencil {
      display: block;
    }
  }
  .nameSide {
    width: 50%;
    border: none;
    // :hover {
    //   border: none;
    // }
    .Publictag {
      font-size: 12px;
      color: white;
      font-weight: bold;
      border-radius: 30px;
      text-transform: uppercase;
      margin: 3px;
      width: auto;
      text-align: center;
      cursor: default;
      justify-content: center;
      padding: 7px 10px;
      display: flex;
    }
    .samiCircle {
      color: rgb(25, 219, 96);
      text-overflow: ellipsis;
      // max-width: 9ch;
      white-space: nowrap;
      // overflow: hidden;
    }
    .textNextToPublicTag {
      align-self: center;
    }
  }
  .buttonsSide {
    display: flex;
    flex-direction: row;
    width: 44%;
    justify-content: end;
  }
  .profile-main {
    height: fit-content;
    &:hover {
      opacity: 30% !important;
    }
  }
}
.shareButton {
  padding: 9px 20px;
  height: 44px;
  background: #68717e;
  text-transform: uppercase;
  font-weight: bold;
  right: 0px;
  margin: 0px 6px;
  color: #fff;
  border-radius: 3px;
}
.perviewButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 140px;

  background-color: black;
  border: 1px solid #22262e;
  opacity: 0.5;
  z-index: 1;
  color: #fff;
}
.subTabs {
  display: flex;
  .simple__tabs__container {
    height: 50px !important;
    align-items: center;
  }
  .subTab {
    margin-left: 30px;
  }
}
.vl {
  border-left: 1px solid #22262e;
  height: 44px;
}
.bannerinput, .bannerinputDisabled {
  display: block;
  position: relative;
  max-width: -webkit-fill-available;
  padding: 13.5px;
  background-color: transparent;
  border-radius: 2px;
  color: #fff;
  outline: 0;
  font-size: 190%;
  font-weight: 700;
  line-height: 15px;
  -webkit-appearance: none;
  appearance: none;
  transition: border 0.3s;
  border: 1px solid transparent !important;
}
.bannerinput:hover{
  border: 1px solid var(--primary);
}
h2 {
  margin-top: 20px;
}
input {
  display: block;
  position: relative;
  width: -webkit-fill-available;
  padding: 13.5px;
  background-color: transparent;
  border-radius: 2px;
  color: #fff;
  outline: 0;
  line-height: 15px;
  -webkit-appearance: none;
  appearance: none;
  transition: border 0.3s;
  border: 2px solid #22262e;
  margin-top: 5px;

  &:hover:enabled {
    border: 1px solid var(--primary) !important;
  }
  &:focus-visible {
    &.invalid {
      border: 1px solid #f11857 !important;
      outline: 2px solid #f11857 !important;
      box-shadow: none;
    }
  }
  &:focus {
    &.invalid {
      border: 1px solid #f11857 !important;
      box-shadow: none;
    }
  }
  &.invalid {
    border: 1px solid #f11857 !important;
  }
}

.Publictag {
  font-size: 12px;
  color: white;
  font-weight: bold;
  border-radius: 30px;
  text-transform: uppercase;
  margin: 3px;
  width: auto;
  text-align: center;
  cursor: default;
  justify-content: center;
  padding: 7px 10px;
  display: flex;
}
.form-input {
  background-color: transparent !important;
  border: 1px solid #22262e ;
}
.error-form-input {
  background-color: transparent !important;
  border: 1px solid #f11857 !important;
  width: 100%;
}
