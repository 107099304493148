.champions-list {
  display: flex;
  margin: 7px 0px;

  img {
    width: 24px;
    height: 24px;
    object-fit: cover;
    margin-right: 6px;
  }

  .team-stat-list-subitem {
    display: flex;
    flex-direction: column;

    span {
      font-size: 10px;
      line-height: 13px;
    }

    &.flex-expand {
      flex: 1;
    }

    &:not(.flex-expand) {
      margin-right: 6px;
    }
  }

  &:last-child {
    margin-bottom: 0px;
  }
}
