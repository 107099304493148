.stageandgrouppage {
    // padding : 0 20px 0 20px;
    h2 {
        height: 19px;
        /* UI Properties */
        text-align: left;
        font: normal normal bold 16px/19px Montserrat;
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 1;
    }
    .stage-row {
        padding: 10px 0 0 0;
        flex-direction: column;
        align-content: flex-start;
        align-items: flex-start;
        h4 {
            margin: 0 0 5px 0;
            height: 15px;
            /* UI Properties */
            text-align: left;
            font: normal normal bold 12px/15px Montserrat;
            letter-spacing: 0px;
            color: #ffffff;
            opacity: 1;
        }
        h5 {
            margin: 0;
            height: 15px;
            /* UI Properties */
            text-align: left;
            font: normal normal normal 12px/15px Montserrat;
            letter-spacing: 0px;
            color: #68717e;
            opacity: 1;
        }
    }
}
