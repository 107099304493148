
.nav-side-buttons {
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-family: Montserrat;
    font-weight: bold;
    color: #fff;
    padding: 20px 15px;
    background-color: transparent;
    border-radius: 2px;
    border: 0;
    margin-left: 8px;
    height: 42px;
    svg {
      margin-right: 10px;
    }
    &:hover {
      cursor: pointer;
    }
  }
  .primary-btn {
    background-color: #3EABF0;
    margin-left: 12px;
  }
  .secondary-btn {
    background-color: #22262E;
  }
  .info-btn {
    border: 1px solid #22262E;
    background: transparent;
    &:hover {
      background: #22262E;
    }
  }
}

// Seeding Body Styles

.seedingAutoFill {
  .MuiIconButton-label {
    color: #3EABF0;
    &:checked {
      color: yellow;
    }
  }
}
.seeding-left-col {
  // border: 1px solid grey;
  margin-top: 11px;
  .compact-card {
    min-width: 240px;
    height: 40px;
    background: #12141A;
    border-radius: 4px;
    opacity: 0.98;
    display: flex;
    align-items: center;
    padding: 10px;
    font-family: 'Montserrat';
    font-size: 12px;
    color: #FFFFFF;
    width: 90%;
    .card-body {
      width: 100%;
    }
    .card-teams-container {
      width: 100%;
      display: flex;
      align-items: center;
      // justify-content: space-between;
      min-width: 220px;
      .team-name-card {
        display: flex;
        align-items: center;
        flex: 1;
        color: #68717E;
        font-family: 'Montserrat';
        font-weight: bold;
        font-size: 12px;
        .team-name {
          font-size: 14px;
          color: #FFFFFF;
        }
        .team-icon {
          height: 17px;
          width: 17px;
          margin-left: 10px;
          margin-right: 10px;
        }
      }
      .drag-handle {
        height: 20px;
        &:hover{
          cursor: grab;
          // cursor: grabbing;
        }
      }
    } 
    .card-no-teams-container {
      width: 100%;
      display: flex;
      align-items: center;
      // justify-content: space-between;
      min-width: 220px;
      .no-team-name-card {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        color: #68717E;
        background: transparent;
        font-family: 'Montserrat';
        font-weight: bold;
        font-size: 14px;
        .team-name {
          font-size: 14px;
          color: #FFFFFF;
        }
        .team-icon {
          height: 17px;
          width: 17px;
          margin-left: 10px;
          margin-right: 10px;
        }
      }
      .drag-handle {
        height: 20px;
        &:hover{
          cursor: grab;
          // cursor: grabbing;
        }
      }
    } 
  }
  .no-teams-borderd{
    border: 1px dashed #68717E;
    background: transparent;
  }
}

.disabled {
  pointer-events: none !important;
  opacity: 0.3 !important;
}

.team-container {
  display: flex;
  align-items: center;
}

.team-icon {
  height: 18px;
  width: 18px;
  margin-left: 10px;
  margin-right: 10px;
}

.greyed-text {
  color: #68717E;
}

.greyed-text-dd {
  color: #68717E;
  font-size: 14px;
  margin-left: 5px;
}

.distribution-main-container {

  .groups-dd-container {
    // width: 200px;
    width: 100%;
  }
  
}

.distributions {
  .dist-container{
    margin-top: 10px;
  }
  .team-card-dist {
    margin: auto !important;
  }
  .header-row {
    margin: 0px 0px 2px 0px !important;
  }
  .m4 {
    margin: 4px 0px 0px 0px !important;
  }
  .m5 {
    margin: 5px 4px 5px 5px !important;
  }
  .m25 {
    border: 1px solid gainsboro !important;
    border-radius: 5px;
    margin: 5px 5px 5px 5px !important;
    width: 300px !important;
  }
  .w300 {
    border: 1px solid gainsboro !important;
    border-radius: 5px;
    margin: 5px 5px 5px 5px !important;
    width: 300px !important;
  }
  .w250 {
    width: 250px !important;
  }
  .w56 {
    width: 56px !important;
  }
  .w57 {
    width: 57px !important;
  }
  .w145 {
    width: 150px !important;
  }
  .w195 {
    width: 195px !important;
  }
  .w100 {
    width: 100% !important;
  }
  .seed-pos {
    font-size:  12px;
    font-weight: bold;
    font-family: Montserrat;
    color: #FFFFFF;
  }
  .group-name {
    margin: 5px 5px 15px 5px;
    text-align: left;
  }
  .input-group {
    border: 1px dashed #68717E;
    overflow: hidden !important;
    white-space: nowrap !important;
    height: 40px;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: bold;
    color: #68717E;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 15px !important;
    width: 70% !important;
    &.filled {
      background-color: #171A22;
      color: #FFFFFF;
      border: 0;
    }
    .cross {
      &:hover {
        cursor: pointer;
      }
    }
    &.no-spot-team {
      color: #68717E;
      border: 1px dashed #68717E;
      background-color: transparent;
    }
  }
  .line {
    font-weight: 900;
    margin: 4px 4px 4px 4px !important;
  }
  .container {
    padding: 5px 5px 5px 5px;
  }
  .group-container {
    // margin: 5px 5px 5px 5px !important;
    // border: 1px solid gainsboro !important;
    padding: 20px 25px 20px 25px !important;
    border-radius: 16px;
    height: min-content;
    width: 366px;
    // max-width: 366px;
    // background-color: #101218;
    background-color: #0e0f16;
    margin-bottom: 20px;
    margin-right: 20px;
  }
  .single-group {
    padding: 0 0 !important;
    background: transparent;
  }
  .group-empty {
    margin: 5px 5px 5px 5px !important;
    padding: 0px 0px 0px 0px !important;
  }
  .groups-row{
    display: flex !important;
    flex-wrap: wrap;
    margin-left: 0;
  }
  .group-row {
    margin: 0px 0px 2px 0px !important;
    width: 100%;
  }
  .group-row-single {
    margin: 5px 5px 5px 5px !important;
  }
  .row-seeding {
    margin: auto;
    justify-content: space-between;
    border: 1px solid gainsboro;
    border-radius: 5px;
    padding: 5px 5px 5px 5px;
  }
  .group-box {    
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
}

.seed-row {
  margin-bottom: 10px;

  margin-left: 0 !important;
  height: 40px;
}

.save-seeding-primary-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-family: Montserrat;
  font-weight: bold;
  color: #fff;
  padding: 20px 15px;
  border-radius: 2px;
  border: 0;
  height: 42px;
  background-color: #3EABF0;
  margin-left: 0;
  svg {
    margin-right: 10px;
  }
  &:hover {
    cursor: pointer;
  }
}

.sub-text {
  color: #68717e;
}