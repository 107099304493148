.accordion {
    background-color: #68717e;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 95%;
    margin-left: 12px;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
    transition: 0.4s;
    border-radius: 5px;
}
  
  .active, .accordion:hover {
    background-color: #464d56;;
  }
  
  .panel-1 {
    padding: 0 18px;
    // background-color: white;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    margin: 7px 10px;
  }

  .modal-footer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 0 0 15px 0px;
    align-items: center;
    justify-content: flex-end;
    .footer-buttons {
        display: flex !important;
        flex-wrap: nowrap;
        flex-direction: row !important;
        .save-changes-btn {
            width: 160px;
            height: 42px;
            border: 0;
            /* UI Properties */
            background: #3eabf0 0% 0% no-repeat padding-box;
            border-radius: 2px;
            opacity: 1;
            span {
                width: 99px;
                height: 15px;
                /* UI Properties */
                text-align: right;
                font: normal normal bold 12px/15px Montserrat;
                letter-spacing: 0px;
                color: #ffffff;
                text-transform: uppercase;
                opacity: 1;
            }
        }
        .cancel-changes-btn {
            margin-left: 10px;
            height: 42px;
            /* UI Properties */
            background: transparent;
            border: 1px solid #22262e;
            border-radius: 2px;
            opacity: 1;
            span {
                width: 52px;
                height: 15px;
                /* UI Properties */
                text-align: right;
                font: normal normal bold 12px/15px Montserrat;
                letter-spacing: 0px;
                color: #ffffff;
                text-transform: uppercase;
                opacity: 1;
            }
        }
    }
  }

.dropdown{
    background-color: red;
    select{
        background-color: #3eabf0 !important;
    }
}