.container-tag {
  background-color: #22262e;
  align-items: center;
  flex-direction: row;
  display: flex;
  width: fit-content;
  margin-top: 20px;
  border-radius: 30px;
  margin-left: 3px !important;
  margin-right: 3px !important;
}
.close {
  margin: 10px;
  color: #68717e;
  right: 11px;
  top: 11px;
  color: rgba(255, 255, 255, 0.6);
  // z-index: 100;
  font-size: 1rem;
}
.search-result-img {
  width: 25px;
  height: 25px;
  background-size: cover;
  background-position: 50% 50%;
  border-radius: 100%;
  flex-shrink: 0;
  margin: 0px 10px;
}

.search-result-tag-img-notCropped {
  width: 25px;
  height: 25px;
  background-size: cover;
  background-position: 50% 50%;
  border-radius: 0% !important;
  flex-shrink: 0;
  margin: 0px 10px;
}
