ul.list {
  li.date-list-item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 0px;
    padding: 15px 0px;
    padding-left: 39px;

    &:before {
      left: 0px;
      position: absolute;
      content: '';
      display: block;
      height: 24px;
      width: 24px;
      border-radius: 15px;
      background-color: $primary;
    }

    &:after {
      background-color: transparentize($primary, 0.85);
      left: 11px;
      width: 1px;
      height: 100%;
      top: 0px;
      position: absolute;
      content: '';
      display: block;
    }

    &:first-child:after {
      height: 50%;
      transform: translateY(100%);
    }

    &:last-child:after {
      height: 50%;
    }
  }
}
