.edit-image-modal {
  .edit-image-controls {
    padding: 20px 20px 10px 20px;
    text-align: center;
    display: flex;

    input {
      width: 100%;
      margin: -5px 5px 0 5px;
    }

    button {
      appearance: none;
      background: none;
      border: 0;
      outline: 0;
      font-size: 1.4rem;
      color: white;
      cursor: pointer;
      padding: 0;
      width: 40px;
    }
  }

  .save-buttons {
    display: flex;
    justify-content: space-between;
    margin: 20px;
  }
}
.reuploadbutton {
  position: absolute;
  top: 1%;
  right: 1%;
  width: fit-content;
  height: 38px;
  background: #68717e 0% 0% no-repeat padding-box;
  border-radius: 2px;
}
.image-dropzone {
  border: 3px dashed #22262e;
  border-radius: 3px;
  padding: 30px 25px;
  height: 181px;
  text-align: center;
  color: #999;
  font-weight: bold;
  // text-transform: uppercase;
  line-height: 1.1rem;
  font-size: 0.8rem;
  cursor: pointer;
  margin-bottom:10px
}

.dotedorder{
  border: 3px dashed red;
  border-radius: 3px;
  padding: 30px 25px;
  text-align: center;
  color: rgb(98, 35, 35);
  font-weight: bold;
  // text-transform: uppercase;
  line-height: 1.1rem;
  font-size: 0.8rem;
  cursor: pointer;
 }

.image-dropzoneonhover {
  // margin: 20px;
  .Uploadimage {
    width: '100%'; height: '25px'; border: '0px' ;color:#68717e
    }
  :hover {
    background-color: #1b2837;
    border: 3px dashed #3eabf0;
    .lableDragAndDrop {
      color: #296f9e;
      // color: #3eabf0;
      border: none;
    }
    
    .Uploadimage :first-child {
    color: #296f9e;
    // color: #296f9e;
    // color: #3eabf0;

    border: none;
    }
    // :hover{
    //   color:  #296f9e !important
    // }
     .UploadimageLabel{
      // color: #296f9e;
      color: #3eabf0;

    }
    // :hover{
    //   color: #3eabf0 !important;
    // }
  }
  .lableDragAndDrop {
    width: '100%';
    border: '0px';
    color: #68717e ;
    // background-color: red;
    font: normal normal normal 10px/13px Montserrat;
    // width: 148px;
    // height: 13px;
  }
  
  // .lableDragAndDrop:hover{
  //  color: red;
  // }
  .Uploadimage {
    width: '100%';
    border: '0px';
    border: none;
    display:flex;
    flex-direction: column;
    align-items: center;
  }
  .UploadimageLabel{
    // color: white;
    // width: 100%;
    // border: none;
top: 389px;
left: 665px;
width: 136px;
height: 15px;
/* UI Properties */
text-align: center;
font: normal normal bold 12px/15px Montserrat;
letter-spacing: 0px;
color: #FFFFFF;
// text-transform: uppercase;
opacity: 1;

  }
}
