@import './../../../styles/variables';
.newfolder-main {
  width: inherit;
}
.newfolder-buttons-unsaved {
  width:100%;
  display: flex;
  justify-content: center;
  padding: 5%;
}
.organizer-unsaved-text {
  font-size:12px;
  font-family: "Montserrat";
  text-align: center;
  width: 90%;
  margin-left: 5%;
}
.form-input1 {
  border: px solid #8b8d91 !important;
  &::placeholder {
    color: #8b8d91 !important;
  }

  &:hover {
    border: 1px solid var(--primary);
  }

  &:focus {
    background-color: $header-bg-color;
    border: 1px solid var(--primary);
  }

  &.invalid {
    border: 1px solid $accent-red;
  }
}
.modal-header-unsaved{
  margin-top: 15px !important;

  font-size: 14px !important;
  font-family: "Montserrat" !important;
  display: flex !important; 
  justify-content: center !important;
}
