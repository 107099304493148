.games-overview-col-header {
  padding-bottom: 10px;
  display: block;
}

.games-overview-col-inner {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &.objectives {
    span {
      display: inline-block;
      margin-right: 10px;
    }
  }
  img.games-overview-ban {
    width: 18px;
    height: 18px;
    margin-right: 6px;
  }

  img.objective-image {
    margin-right: 4px;
  }
}

.gold-ratio-bar-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;

  > span:first-child {
    margin: 0px 16px;
    margin-right: 8px;
  }
  > span:last-child {
    margin: 0px 16px;
    margin-left: 8px;
  }

  .gold-ratio-bar {
    flex: 1;
    height: 14px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;

    .primary,
    .success,
    .danger {
      height: 100%;
    }

    .success {
      background-color: $success;
      width: 66%;
    }

    .primary {
      background-color: var(--primary);
      width: 66%;
    }

    .danger {
      background-color: $danger;
      width: 34%;
    }
  }
}
