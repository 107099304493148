#context-menu {
  width: 89px;
  height: auto;
//   display: none;
  position: fixed;
  background: #0f1116 0% 0% no-repeat padding-box;
  border: 1px solid #22262e;
  border-radius: 2px;
  opacity: 1;
  z-index: 100;
  transform: scale(0);
  // margin-top: 13px !important;
  // margin-left: -12px !important;
  padding: 4px;
  transform-origin: top left;
}
#context-menu.visible {
//   display: block;
  transform: scale(1);
  transition: transform 200ms ease-in-out;
}
