.checkbox-slider-wrapper {
  display: flex;
  align-items: center;
  // margin: 10px 0px;
  margin: 11px 0px -10px 0px;

  .icon {
    margin-left: 10px;
    text-align: center;
    margin-bottom: 8px;
    background-color: #373b40;
    border-radius: 50%;
    width: 20px;

    .tooltiptext {
      visibility: hidden;
      width: auto;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      padding: 5px 0;
      /* Position the tooltip */
      position: absolute;
      z-index: 1;
      font-weight: normal;
      font-size: 10px;
      background-color: #535a65;
    }

    &:hover .tooltiptext {
      visibility: visible;
    }

    &:hover {
      cursor: pointer;
    }
  }

  input[type='checkbox'] {
    display: none;
  }

  label {
    cursor: pointer;
    pointer-events: inherit;
  }

  .checkbox-slider {
    position: relative;
    display: inline-block;
    background-color: lighten($bg-faded, 5%);
    border-radius: 30px;
    // height: 26px;
    height: 20px;
    // width: 46px;
    width: 35px;
    // min-width: 46px;
    min-width: 35px;
    margin-right: 10px;
    transition: background-color 0.3s;
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      background-color: $text-faded-color;
      left: 3px;
      top: 3px;
      // width: 20px;
      // height: 20px;
      width: 15px;
      height: 15px;
      border-radius: 18px;
      transition: background-color 0.3s, left 0.3s;
    }
  }

  .checkbox-slider-custom {
    position: relative;
    display: inline-block;
    background-color: lighten($bg-faded, 5%);
    border-radius: 30px;
    height: 19px;
    width: 40px;
    min-width: 35px;
    margin-right: 10px;
    transition: background-color 0.3s;
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      background-color: $text-faded-color;
      left: 3px;
      top: 2px;
      width: 15px;
      height: 15px;
      border-radius: 18px;
      transition: background-color 0.3s, left 0.3s;
    }
  }

  .label-setting {
    font-size: 13px;
  }

  input[type='checkbox']:checked + .checkbox-slider {
    background-color: var(--primary);

    &::before {
      background-color: $white;
      // left: 23px;
      left: 17px;
    }
  }
  input[type='checkbox']:checked + .checkbox-slider-custom {
    background-color: var(--primary);

    &::before {
      background-color: $white;
      left: 23px;
    }
  }
}

.height-auto {
  height: auto !important;
  margin-top: 11%;
}
.iputdatetime[type="datetime-local"]:before {
  content: 'Select latest date'  !important;
  // text-align: center;
  width: 100%;
}
.iputdatetime1[type="datetime-local"]:before {
  content: 'Select earliest date'  !important;
  // text-align: center;
  width: 100%;
}
