.Info {
  font-size: 0.9rem;
  &::placeholder {
    color: #818386;
  }
}
.plachodercolor::placeholder{
  color: #818386;
}
a img, :link img, :visited img {

  border: 0;

}
.edit-input-border input{
  border:1px solid #22262e;
  height: 44px;
}

input#discord::placeholder{
  color:#818386
}