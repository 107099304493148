.filter-row {
  // border-bottom: 1px solid #22262e;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #22262e;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #0b0c0f;
  }
  &:active {
    background-color: #232730;
  }
  &.no-border {
    border-bottom: none;
  }
}
.tabs-container.tab-content {
  padding-top: 0px;
}
.filter-item {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.item-count {
  border-radius: 20px;
  padding: 8px;
  background-color: rgba(34, 38, 46, 1);
  font-size: 12px;
  margin-right: 5px;
}
.filter-content {
  border-left: 5px solid transparent;
  padding: 14px 20px !important;
  transition: border 0.4s ease-out;
  width: 100%;
  justify-content: space-between;
}
.selectedRow {
  border-left: 5px solid var(--primary);
  // padding: 15px 20px 15px 15px !important;
}
