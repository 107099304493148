.matchsettingspage {
    // padding : 0 20px 0 20px;
    select{
        height: 44px !important;
    }
    #dateTime-pg2 {
        text-overflow: ellipsis;
        // height: 15px;
        color-scheme: dark;

        /* UI Properties */
        text-align: left;
        font: normal normal normal 12px/15px Montserrat;
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 0.5;
    }
    .row-heading-two {
        flex-wrap: nowrap;

        flex-direction: row;
        align-content: center;
        justify-content: space-between;

        h2 {
            height: 19px;
            /* UI Properties */
            text-align: left;
            font: normal normal bold 16px/19px Montserrat;
            letter-spacing: 0px;
            color: #ffffff;
            opacity: 1;
        }
        .tag-team-round-matches {
            padding: 0 10px 0 10px;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: center;
            align-items: center;
            height: 24px;
            max-width: 100%;
            /* UI Properties */
            background: #22262e 0% 0% no-repeat padding-box;
            border-radius: 100px;
            opacity: 1;
            .num {
                height: 13px;
                /* UI Properties */
                text-align: left;
                font: normal normal bold 10px/13px Montserrat;
                letter-spacing: 0px;
                color: #ffffff;
                span {
                    color: #68717e;
                    text-transform: uppercase;
                    margin-right: 7px;
                }
            }
        }
        .w-100 {
            max-width: 100% !important;
        }
    }
    p {
        color: #68717e;
        font: normal normal bold 10px/13px Montserrat;
        letter-spacing: 0px;
        text-transform: uppercase;
        opacity: 1;
        height: 13px;
    }
    .start-date-time-row {
        justify-content: space-between;
        .equal-columns {
            flex: 0 0 49%;
            .gray-bg {
                background-color: #1b1e26 !important;
            }
            .black-bg {
                background-color: transparent !important;
            }
            #timezone-select01 {
                // width: auto;
                opacity: 0.5;
                padding-right: 25px;
                text-overflow: ellipsis;
                text-transform: capitalize;
            }
        }
        label {
            color: #ffff;
        }
        input[type="date"] {
            height: 40px;
            // height: 15px;
            /* UI Properties */
            text-align: left;
            font: normal normal normal 12px/15px Montserrat;
            letter-spacing: 0px;
            color: #ffffff;
            opacity: 0.5;
        }
        select {
            // margin: 5px 0 0;
            height: 40px;
            padding: 0 0 0 10px;
        }
    }
    .stage-row {
        padding: 10px 0 0 0;
        flex-direction: column;
        align-content: flex-start;
        align-items: flex-start;
        h4 {
            text-transform: uppercase;
            margin: 0 0 5px 0;
            height: 15px;
            /* UI Properties */
            text-align: left;
            font: normal normal bold 12px/15px Montserrat;
            letter-spacing: 0px;
            color: #ffffff;
            opacity: 1;
        }
        h5 {
            margin: 0;
            height: 15px;
            /* UI Properties */
            text-align: left;
            font: normal normal normal 12px/15px Montserrat;
            letter-spacing: 0px;
            color: #68717e;
            opacity: 1;
        }
    }
    .alternat-sides-row {
        h5 {
            padding: 0 0 0 10px;
            text-transform: uppercase;
        }
    }
    .addons-row-col {
        // flex-direction: column;
        // align-content: flex-start;
        // flex-wrap: nowrap;
        // align-items: flex-start;
    }
    .divided-two {
        display: flex;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-evenly;
        align-items: center;
    }
}
.w-100 {
    max-width: 100%;
}
