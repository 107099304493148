.spreadOptions {
  width: auto;
  height: auto;
padding: 5px;
  position: absolute;
  background: #0F1116 0% 0% no-repeat padding-box;
  border: 1px solid #22262e;
  border-radius: 2px;
  opacity: 1;
  z-index: 100;
}
.hi-dots {
  // margin-left: 40px;
}
.list-item {
  color: white;
  padding: 5px;
  cursor: pointer;
}
