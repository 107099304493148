.content {
  width: 450px;
}

.swal2-popup {
  background: #121418 !important;
  .swal2-html-container {
    margin: 0.5em 1.2em 0.3em !important;
    font-size: 1em !important;
  }
  .swal2-warning {
    border-color: #515151 !important;
    color: #515151 !important;
    width: 2em !important;
    height: 2em !important;
    .swal2-icon-content {
      font-size: 1.75em !important;
    }
  }
  .swal2-styled.swal2-confirm {
    background-color: var(--primary) !important;
  }
}

.eventDot {
  margin-right: 10px !important;
  margin-top: 27px !important;
  margin: 4px;
  box-sizing: inherit !important;
  width: 15px !important;
  height: 1px !important;
  border: 10px solid #3788d8;
  border-radius: 3px !important;
}

.heading {
  margin-top: 28px;
  font-weight: bold;
}

.subHeading {
  font-size: 13px;
  font-weight: 400;
  margin-top: 5px;
}

.description {
  display: flex;
  margin-top: 10px;
  font-size: 20px;
  margin-bottom: 10px;
}

.bell {
  display: flex;
  font-size: 19px;
  // margin-left: -5px;
}

.col-11 {
  padding-right: 0px !important;
}

.calendar {
  margin-top: 10px;
  font-size: 19px;
  display: flex;
  margin-bottom: 5px;
}

.ant-popover {
  pointer-events: all !important;
  position: absolute !important;
  z-index: 999;
}

.eventModal {
  position: relative;
  width: 400px;
  background: $bg-faded;
  color: #fff;
  outline: 0;
  border-radius: 3px;
  z-index: 999;
  max-height: calc(100vh - 100px);

  //overflow: hidden;
  &.match-modal {
    width: 1100px;
  }

  &.modal-inline {
    position: relative;

    &.on-page {
      z-index: 9;
    }
  }

  .modal-close {
    top: 5px;
    right: 10px;
    position: absolute;
    color: #68717e;
    font-size: 30px;
    cursor: pointer;
  }

  .headerDelIcon {
    top: 10px;
    right: 50px;
    position: absolute;
    color: #68717e;
    font-size: 20px;
    cursor: pointer;

    svg {
      margin-left: 2px;
      height: 19px;
    }

    &:hover {
      background: #242323;
      border-radius: 12px;
    }
  }

  .icon {
    width: 25px !important;
  }

  .headerEditIcon {
    top: 10px;
    right: 90px;
    position: absolute;
    color: #68717e;
    font-size: 20px;
    cursor: pointer;

    svg {
      margin-left: 2px;
      height: 19px;
    }

    &:hover {
      background: #242323;
      border-radius: 12px;
    }
  }

  .modal-scrollable {
    overflow: auto;
    max-height: calc(100vh - 100px);
  }

  .modal-header {
    text-align: center;
    padding: 15px 20px;
    border-bottom: 1px solid $tab-border-color;

    h2 {
      font-size: 1.2rem;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 15px;
    }

    h3 {
      font-size: 0.75rem;
      line-height: 1rem;
      font-weight: normal;
      color: $text-faded-color;
    }

    h4 {
      font-size: 0.75rem;
      line-height: 1rem;
      font-weight: normal;
      color: $text-faded-color;
    }
  }

  .modal-body {
    padding: 20px;

    .label {
      font-size: 13px !important;
      font-weight: normal !important;
      color: white !important;
      display: block;
      text-transform: none;
      margin-left: 10px;
      margin-top: 5px;
    }

    .next-buttons {
      display: flex;
      justify-content: space-between;
    }
  }

  .modal-information {
    border-top: 1px solid #22262e;
    margin-left: -20px;
    margin-right: -20px;
    padding: 10px 20px;
    background: #22262e;

    h3 {
      font-size: 1.2rem;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 15px;
    }

    p {
      font-size: 0.75rem;
      line-height: 1rem;
      font-weight: normal;
      color: #68717e;
    }
  }

  .requestButton {
    display: inline;
    float: left;
    width: 70%;
    height: 43px;
    padding: 9px 20px;
    font-weight: bold;
    font-size: 12px;
    text-transform: uppercase;
    text-align: center;
    background-color: var(--primary);
    color: white;
    border: 0;
    border-radius: 3px;
    outline: 0;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-in-out;

    &:hover {
      box-shadow: -1px -1px 5px var(--primary), 1px -1px 5px var(--primary),
        -1px 1px 5px var(--primary), 1px 1px 5px var(--primary);
    }
  }
}
