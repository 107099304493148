.organiser-item-main-div {
    .border-clip-for-color {
        // padding: 1px 1px 1px 1px;
        clip-path: polygon(16% 0%, 100% 0%, 100% 100%, 0% 100%, 0% 28%);
        aspect-ratio: 16 / 9;
        // max-width: 400px;
        background: var(--primary);

        .image-container-OI {
            aspect-ratio: 16 / 9;
            clip-path: polygon(16.1% 0.8%, 99.6% 0.8%, 99.6% 99.3%, 0.4% 99.3%, 0.4% 28.3%);
            // margin: 0 0 0px 1px;
            // max-width: 400px;
        }
    }
}
.margin-39 {
    margin-right: 39px !important;
}
.flex-240 {
    flex: 0 0 240px !important;
}
