.prizes-tab-pages-05 {
    .no-prizes-div {
        display: flex;
        align-items: center;
        flex-direction: column;
        .create-prize-btn{
            margin-top: 5px;
        }
        .circle-around-logo {
            background-color: #171c23;
            border-radius: 50%;
            padding: 7px;
            display: flex;
        }
        .not-created {
            margin-top:15px;
            height: 19px;
            /* UI Properties */
            text-align: center;
            font: normal normal bold 16px/19px Montserrat;
            letter-spacing: 0px;
            color: #ffffff;
            text-transform: uppercase;
            opacity: 1;
        }
        .create-prizes-for-participants {
            height: 25px;
            margin-top: 10px;
            /* UI Properties */
            text-align: center;
            font: normal normal normal 12px/15px Montserrat;
            letter-spacing: 0px;
            color: #68717E;
            opacity: 1;
        }
    }
    .table-of-prizes05 {
        border-collapse: collapse;
        tr {
            border-bottom: 1px solid #1d1f26;
        }
        th td {
            display: flex;
            align-items: center;
            vertical-align: center;
        }
        .place-column {
            display: flex;
            align-items: center;
            .circle-around-logo {
                background-color: #171c23;
                border-radius: 50%;
                padding: 7px;
                display: flex;
            }
        }
        td button {
            display: flex;
            align-items: center;
            span {
                height: 15px;
                /* UI Properties */
                text-align: right;
                font: normal normal bold 12px/15px Montserrat;
                letter-spacing: 0px;
                color: #f11857;
                opacity: 1;
                padding-left: 5px;
            }
        }
    }
}
