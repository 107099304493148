.logo-grid-cards {
    align-items: center;
    display: flex;
    img {
        width: 25px;
        height: 20px;
    }
}
.circle-count-top {
    position: absolute;
    // z-index: 1000;
    float: right;
    width: 20px;
    height: 20px;
    color: #fff;
    border-radius: 50%;
    background: #fd1049;
    text-align: center;
    top: -6px;
    right: -11px;
    display: flex;
    justify-content: center;
    align-items: center;
    font: normal normal bold 12px/15px Montserrat;

}
