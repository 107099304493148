.card-panel {
  display: flex;
  flex-direction: row;
  place-content: space-between;
  padding: 15px 20px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
}

.card-x {
  background-color:  rgb(62, 171, 240);
  color: white;
  background:  rgb(62, 171, 240);;
  display: block;
  width: 136px;
  padding: 9px 4px 9px 5px;
  text-transform: uppercase;
  border: solid 1px rgba(112, 112, 112, 0.1);
  font-weight: bold;
  height: auto;
  margin: 12px 12px 12px 0px;
  border-radius:3px;
  margin-top: 35px;
  margin-right:-6px;
}
.folder {
  border: 1px solid #20394978;
  color: var(--primary);
  border-radius: 5px;
  width: 245px;
  // display: inline-block;
  margin: 5px 20px 25px 0;
  font-weight: 500;
  text-transform: uppercase;
  &:hover {
    border: 1px solid var(--primary);
  }
}
.newfolder {
  // border: 1px dashed #68717e;
  // border: 1px dashed #1c202a;
  color: #68717e;
  border-radius: 8px;
  width: 280px;
  height: 50px ;
  // display: inline-block;
  // margin: 10px;
  margin: 5px 20px 25px 0;

  font-weight: 500;
  text-transform: uppercase;
  &:hover {
    // border: 1px dashed #20597e;
    color: #3eabf0;
  }
}
.hi-dots {
  border-radius: 50%;
  background-color: rgb(32, 30, 30);
}
.texteclips {
  max-width: 250px;
  text-overflow: ellipsis;
  margin-left: 10px;
  height: 18px;
  width: 250px;
  padding: 0;
  overflow: hidden;
  position: relative;
  display: inline-block;
  margin: 0 5px 0 5px;
  text-align: left;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: bold;
}
.cap span{
  display: inline-block;
  text-transform: lowercase;
}
.cap span::first-letter{
  text-transform: uppercase;
}
.add-folder-btn-gaped-border{
    .dotted-svg-1{
      position: absolute;
      // stroke :#22262e;
      stroke :#1c202a;
    }
    &:hover {
      .dotted-svg-1{
        // stroke :var(--primary); 
        stroke :#20597e; 
      }
      // border: 2px dashed var(--primary); 
    
    }
}