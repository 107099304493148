.weeklyschedulepage {
    .row-heading-two {
        flex-wrap: nowrap;

        flex-direction: row;
        align-content: center;
        justify-content: space-between;

        h2 {
            height: 19px;
            /* UI Properties */
            text-align: left;
            font: normal normal bold 16px/19px Montserrat;
            letter-spacing: 0px;
            color: #ffffff;
            opacity: 1;
        }
        .tag-team-round-matches {
            padding: 0 10px 0 10px;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: center;
            align-items: center;
            height: 24px;
            max-width: 100%;
            /* UI Properties */
            background: #22262e 0% 0% no-repeat padding-box;
            border-radius: 100px;
            opacity: 1;
            .num {
                height: 13px;
                /* UI Properties */
                text-align: left;
                font: normal normal bold 10px/13px Montserrat;
                letter-spacing: 0px;
                color: #ffffff;
                span {
                    color: #68717e;
                    text-transform: uppercase;
                    margin-right: 7px;
                }
            }
        }
        .w-100 {
            max-width: 100% !important;
        }
    }
    .time-error-3 {
        height: 13px;
        /* UI Properties */
        text-align: left;
        font: normal normal normal 10px/13px Montserrat;
        letter-spacing: 0px;
        color: #f11857;
        opacity: 1;
        text-transform: none;
    }

    p {
        color: #68717e;
        font: normal normal bold 10px/13px Montserrat;
        letter-spacing: 0px;
        text-transform: uppercase;
        opacity: 1;
        height: 13px;
    }
    .start-date-time-row {
        justify-content: space-between;
        .equal-columns {
            flex: 0 0 49%;
            .gray-bg {
                background-color: #1b1e26 !important;
            }
        }
        label {
            color: #ffff;
        }
        input[type="date"] {
            height: 40px;
            // height: 15px;
            /* UI Properties */
            text-align: left;
            font: normal normal normal 12px/15px Montserrat;
            letter-spacing: 0px;
            color: #ffffff;
            opacity: 0.5;
        }
    }
    .stage-row {
        padding: 10px 35px 10px 35px;
        margin: 0px -35px 0px -35px;
        background: #1a1d26 0% 0% no-repeat padding-box;
        // padding: 10px 0 0 0;
        flex-direction: column;
        align-content: flex-start;
        align-items: flex-start;
        .time-interval-row {
            margin-top: 10px;
            flex-wrap: nowrap;
            justify-content: flex-start;
            flex-direction: row;
            align-content: center;
            align-items: center;
            input[type="number"] {
                width: 8px;
                // height: 15px;
                padding-left: 10px !important;
                /* UI Properties */
                text-align: left;
                font: normal normal normal 12px/15px Montserrat;
                letter-spacing: 0px;
                color: #ffffff;
                opacity: 1;
            }
            .join-input {
                flex: 0 0 30%;
                margin: 0;
                padding: 0;
                height: 30px;
                /* UI Properties */
                // border: 1px solid #22262e !important;
                border-color: #22262e;
                border-style: solid;
                // border-width: 1px 0px 1px 1px;
                border-width: 1px 1px 1px 1px;
                margin-right: 5px;
              
                border-radius: 2px;
                opacity: 1;
                &:hover{
                    border-color: var(--primary);
                    border-style: solid;
                    border-width: 1px 1px 1px 1px !important;
                }
                &:active{
                    border: none !important;
                }
                &:focus-visible{
                    outline: none;
                    border: 1px solid var(--primary);
                }
            }
            .join-input-error {
                flex: 0 0 30%;
                margin: 0;
                padding: 0;
                height: 30px;
                /* UI Properties */
                // border: 1px solid #f11857 !important;
                border-color:  #f11857 !important;
                    border-style: solid;
                    // border-width: 1px 0px 1px 1px !important;
                    border-width: 1px 1px 1px 1px !important;
                    margin-right: 5px;
                border-radius: 2px;
                opacity: 1;
                &:focus-visible{
                    outline:none;
                    border:1px solid #f11857;
                }
            }
            .join-select {
                flex: 0 0 40%;
                margin: 0 0 0 0px;
                padding: 0 10px 0 10px;
                height: 30px;
                /* UI Properties */
                background-color: #1e222c !important;
                // border: 1px solid #22262e !important;
                border-color: #22262e !important;
                border-style: solid;
                // border-width: 1px 1px 1px 0px !important;
                // border-radius: 0px 2px 2px 0px;
                border-width: 1px 1px 1px 1px !important;
                border-radius: 2px 2px 2px 2px;
                opacity: 1;
            }
            .join-select-error {
                flex: 0 0 40%;
                margin: 0 0 0 0px;
                padding: 0 10px 0 10px;
                height: 30px;
                /* UI Properties */
                background-color: #1e222c !important;
                // border: 1px solid #f11857 !important;
                border-color:  #f11857 !important;
                border-style: solid;
                // border-width: 1px 1px 1px 0px !important;
                border-width: 1px 1px 1px 1px !important;
                // border-radius: 0px 2px 2px 0px;
                border-radius: 2px 2px 2px 2px;
                opacity: 1;
            }
            .btn-btn-addTimeBtn {
                margin: 0 10px 0 10px !important;
                min-width: 80px;
                height: 30px;
                /* UI Properties */
                background: #68717e 0% 0% no-repeat padding-box;
                border-radius: 2px;
                border: 0;
                opacity: 1;
                span {
                    height: 13px;
                    /* UI Properties */
                    text-align: right;
                    font: normal normal bold 10px/13px Montserrat;
                    letter-spacing: 0px;
                    color: #ffffff;
                    text-transform: uppercase;
                    opacity: 1;
                }
            }
            .btn-btn-resetBtn {
                min-width: 57px;
                height: 30px;
                /* UI Properties */
                background: transparent;
                border: 1px solid #22262e;
                border-radius: 2px;
                opacity: 1;
                span {
                    height: 13px;
                    /* UI Properties */
                    text-align: right;
                    font: normal normal bold 10px/13px Montserrat;
                    letter-spacing: 0px;
                    color: #ffffff;
                    text-transform: uppercase;
                    opacity: 1;
                }
            }
        }
        h4 {
            text-transform: uppercase;
            margin: 0 0 5px 0;
            height: 15px;
            /* UI Properties */
            text-align: left;
            font: normal normal bold 12px/15px Montserrat;
            letter-spacing: 0px;
            color: #ffffff;
            opacity: 1;
        }
        h5 {
            margin: 0;
            height: 15px;
            /* UI Properties */
            text-align: left;
            font: normal normal normal 12px/15px Montserrat;
            letter-spacing: 0px;
            color: #68717e;
            opacity: 1;
        }
    }
    .match-scheduling-wrapper-row {
        max-height: 300px;
        .match-schedule-row {
            width: 100%;
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            align-content: center;
            padding: 5px 0px 0 0;
            &:last-child {
                padding-bottom: 10px;
            }
            .column-70-w {
                flex: 0 0 68%;
                label {
                    height: 15px;
                    /* UI Properties */
                    text-align: left;
                    font: normal normal bold 12px/15px Montserrat;
                    letter-spacing: 0px;
                    color: #ffffff;
                }
                select {
                    text-transform: capitalize;
                }
            }
            .column-30-w {
                flex: 0 0 30%;
                label {
                    height: 15px;
                    /* UI Properties */
                    text-align: left;
                    font: normal normal bold 12px/15px Montserrat;
                    letter-spacing: 0px;
                    color: #ffffff;
                }

                input {
                    text-overflow: ellipsis;
                    // height: 15px;
                    color-scheme: dark;
                    /* UI Properties */
                    text-align: left;
                    font: normal normal normal 12px/15px Montserrat;
                    letter-spacing: 0px;
                    color: #ffffff;
                    opacity: 1;

                    ::-webkit-calendar-picker-indicator {
                        filter: invert(1);
                    }
                }
            }
        }
        .E246 {
            &:last-child {
                padding-bottom: 10px;
            }
        }
    }
}
