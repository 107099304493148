.no-border {
  border-bottom: none !important;
}
.add-stage-btn {
  width: 92px;
  height: 30px;
  color: white !important;
  // margin-top: -1vh;
  font-size: x-small;
  padding: 5px 5px 5px 5px !important;
  /* UI Properties */
  background: #68717e 0% 0% no-repeat padding-box !important;
  border-radius: 2px;
  opacity: 1;
}

.side-buttons {
  display: inherit;
  //margin-top: -20px !important;
  button {
    color: white !important;
  }
  .preview-brackets-btn {
    width: 100px;
    height: 42px;
    margin: auto;
    /* UI Properties */
    background: #22262e 0% 0% no-repeat padding-box !important;
    border-radius: 2px;
    padding: 0px !important;
    opacity: 1;
       span {
        display: flex;
        justify-content: center;
        align-items: center;
      width: 131px;
      height: 15px;
      /* UI Properties */
      text-align: left;
      font: normal normal bold 12px/15px Montserrat;
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
    }
  }
  .button-primary {
    background-color: var(--primary) !important;
    border-radius: 2px;
    opacity: 1;
    width: 128px;
    height: 42px;
    padding: inherit !important;
    margin-right: 10px !important;
  }
  .button-primary-disabled {
    background-color: #12141a !important;
    color: #717276 !important;
    border-radius: 2px;
    opacity: 1;
    width: 128px;
    height: 42px;
    padding: inherit !important;
    margin-right: 10px !important;
  }
  .button-delete {
    background-color: #f11857 !important;
    border-radius: 2px;
    opacity: 1;
    width: 128px;
    height: 42px;
    padding: inherit !important;
  }
  .button-delete-disabled {
    background-color: #12141a !important;
    color: #717276 !important;
    border-radius: 2px;
    opacity: 1;
    width: 128px;
    height: 42px;
    padding: inherit !important;
  }
  // .preview-brackets-btn {
  //   background-color: var(#68717e) !important;
  // }
}
// .button-in-stages{
//   button{
//     margin-bottom: 5px !important;
//   }
// }
.unsaved-stage {
  // font-style: italic !important;
  color: #f11857;
  &::after {
    content: "*";
  }
}
