@import './../../../../styles/variables';
.newfolder-main {
  width: inherit;
}
.newfolder-buttons {
  float: right;
  padding: 5%;
}
.organizer-delete-text {
  font-family: inherit;
  width: 90%;
  margin-left: 5%;
}
.form-input1 {
  border: px solid #8b8d91 !important;
  &::placeholder {
    color: #8b8d91 !important;
  }

  &:hover {
    border: 1px solid var(--primary);
  }

  &:focus {
    background-color: $header-bg-color;
    border: 1px solid var(--primary);
  }

  &.invalid {
    border: 1px solid $accent-red;
  }
}
